import { Controller } from "@hotwired/stimulus";
const Sticky = require("sticky-js");

// Connects to data-controller="sticky-scroll"
export default class extends Controller {
  static values = {
    selector: String,
    marginTop: {type: Number, default: 0},
    stickyFor: {type: Number, default: 0},
    stickyClass: {type: String, default: "stickied"},
    stickyContainer: {type: String, default: "body"}
  };

  connect() {
    this._sticky = new Sticky(this.selectorValue, {
      marginTop: this.marginTopValue,
      marginBottom: this.marginBottomValue,
      stickyFor: this.stickyForValue,
      stickyClass: this.stickyClassValue,
      stickyContainer: this.stickyContainerValue
    });
  }

  disconnect() {
    this._sticky.destroy();
  }
}