import { Controller } from "@hotwired/stimulus";
import { triggerEvent } from "../utils/events";

// Connects to data-controller="mosaic-bs--checkbox"
export default class extends Controller {
  static targets = ["checkboxAll", "checkboxOne"];
  static values = {
    checked: {type: Array, default: []}
  };

  checkAll() {
    this.checkboxes.forEach(ckbox => {
      this.setCheckboxState(ckbox, this.checkboxAllChecked);
    });

    // Trigger event to notify other controllers
    triggerEvent(this.element, "all-checked", {
      value: this.checkboxesValues,
      checked: this.checkboxAllChecked
    });
  }

  checkOne(event) {
    if (this.hasCheckboxOneTarget === false) return;

    const {value, checked} = event.currentTarget;
    this._updateCheckedValue(value, checked);

    // Uncheck checkboxAll if all checkboxOne is unchecked
    if (this.totalChecked === 0) this.checkboxAllTarget.checked = false;

    // Trigger event to notify other controllers
    triggerEvent(this.element, "one-checked", {
      value,
      checked
    });
  }

  uncheckAll() {
    this.checkboxAllTarget.checked = false;
    this.checkboxAllTarget.indeterminate = false;
    this.checkboxes.forEach(ckbox => {
      ckbox.checked = false;
      ckbox.indeterminate = false;
    });
    this.checkedValue = [];
  }

  setCheckedByValue(values) {
    this.checkboxes.forEach(ckbox => {
      this.setCheckboxState(ckbox, values.includes(ckbox.value));
    });
  }

  setCheckboxState(ckbox, checked) {
    ckbox.checked = checked;
    ckbox.indeterminate = false;
    this._updateCheckedValue(ckbox.value, checked);
  }

  setAllCheckboxIndeterminate(checked) {
    this.checkboxAllTarget.indeterminate = checked;
    this.checkboxes.forEach(ckbox => ckbox.indeterminate = checked);
  }

  _updateCheckedValue(value, checked) {
    const setValues = new Set(this.checkedValue);
    checked ? setValues.add(value) : setValues.delete(value);
    this.checkedValue = Array.from(setValues);
  }

  get totalChecked() {
    return this.checkedValue.length;
  }

  get checkboxes() {
    if (this.countCheckbox === 0) return [];

    return this.checkboxOneTargets;
  }

  get checkboxesValues() {
    return this.checkboxes.map(ckbox => ckbox.value);
  }

  get countCheckbox() {
    if (this.hasCheckboxOneTarget === false || this.checkboxOneTargets.length === 0) return 0;

    return this.checkboxOneTargets.length;
  }

  get checkboxAllChecked() {
    if (this.hasCheckboxAllTarget === false) return false;

    return this.checkboxAllTarget.checked;
  }
};