import { Controller } from "@hotwired/stimulus";
import { useMemo } from "stimulus-use";
import { updateUrlParam, removeUrlParam } from "../../utils/url";
import { toggleDisabled } from "../../utils/visibility";

// Connects to data-controller="accounts--icms-tax-rates--table"
export default class extends Controller {
  static targets = [
    "btnEditValidity"
  ];
  static memos = [
    "buttonTargets"
  ];

  connect() {
    useMemo(this);
  }

  onCheckboxChange({detail: {pageValues, searchAllChecked, searchParamValues}}) {
    const noSearchAll = searchAllChecked === undefined || searchAllChecked === false;
    this._disableButtons(pageValues.length < 1 && noSearchAll);

    if (searchAllChecked) {
      this._removeUrlParam("ids");
      this._updateUrlParam("search", searchParamValues);
    } else {
      this._removeUrlParam("search");
      this._updateUrlParam("ids", pageValues);
    }
  }

  _updateUrlParam(paramName, values) {
    this.buttonTargets.forEach(target => target.href = updateUrlParam(target.href, paramName, values));
  }

  _removeUrlParam(paramName) {
    this.buttonTargets.forEach(target => target.href = removeUrlParam(target.href, paramName));
  }

  _disableButtons(disabled = false) {
    this.buttonTargets.forEach(target => toggleDisabled(target, disabled));
  }

  get buttonTargets() {
    let buttons = [];
    if (this.hasBtnEditValidityTarget) buttons.push(this.btnEditValidityTarget);
    return buttons;
  }

}
