import React from 'react';
import { SnackbarProvider } from 'notistack';
import { ConfirmProvider } from 'material-ui-confirm';
import moment from 'moment'
import 'moment/locale/pt-br';
import { HashRouter as Router } from "react-router-dom";

import SnackbarCloseButton from './common/SnackbarCloseButton'
import TableGrid from './scenariosCalculations/TableGrid';

moment.locale('pt-br');

function ScenariosCalculations(props) {
  return (
    <Router>
      <SnackbarProvider
        action={key => <SnackbarCloseButton snackbarKey={key} />}
        maxSnack={5}
        dense
      >
        <ConfirmProvider
          defaultOptions={{
            confirmationText: 'Sim!',
            cancellationText: 'Cancelar',
            title: 'Você tem certeza?'
          }}
        >
          <TableGrid {...props} />
        </ConfirmProvider>
      </SnackbarProvider>
    </Router>
  );
}

export default ScenariosCalculations;