import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="log-viewer"
export default class extends Controller {
  connect() {
    // console.log("connected!");
  }

  disconnect() {
    // console.log("disconnected!");
  }
}
