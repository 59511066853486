export const toggleDisabled = (target, test) => {
  if (typeof(target) === 'undefined') return;

  target.classList.toggle("disabled", test)
  target.toggleAttribute("disabled", test);
  target.toggleAttribute("aria-disabled", test);
}

export const toggleVisible = (target, test) => {
  if (typeof(target) === 'undefined') return;

  target.classList.toggle("hidden", !test)
  target.toggleAttribute("hidden", !test);
}

export const isVisible = (target) => {
  if (typeof(target) === 'undefined') return;

  return target.classList.contains("hidden") === false
}