import { Controller } from "@hotwired/stimulus";
import { useMemo, useDebounce } from "stimulus-use";
import axios from "axios";

// Connects to data-controller="auto-refresh"
export default class extends Controller {
  static debounces = ["refresh"];
  static memos = ["csrfToken"];
  static values = {
    targetUrl: {type: String, default: ""},
    targetMethod: {type: String, default: "get"},
    refreshInterval: {type: Number, default: 5000}
  };

  connect() {
    useMemo(this);
    useDebounce(this, {wait: 300});
    this.refresh();

    if (this.hasRefreshIntervalValue) {
      this.startRefreshing();
    }
  }

  disconnect() {
    this.stopRefreshing();
  }

  refresh(event) {
    if (typeof event !== "undefined") {
      // console.log("preventDefault")
      event.preventDefault();
    }

    axios({
      url: this.targetUrlValue,
      method: this.targetMethodValue,
      headers: {
        "Accept": "text/vnd.turbo-stream.html; charset=utf-8",
        "Content-Type": "text/vnd.turbo-stream.html; charset=utf-8",
        "X-CSRF-TOKEN": this.csrfToken
      }
    }).then(response => {
      Turbo.renderStreamMessage(response.data);
      return response.status;
    }).catch(err => {
      console.log(err.message);
      return err.toJSON();
    });
  }

  startRefreshing() {
    this.refreshTimer = setInterval(() => {
      this.refresh();
    }, this.refreshIntervalValue);
  }

  stopRefreshing() {
    if (this.refreshTimer) {
      clearInterval(this.refreshTimer);
    }
  }

  get csrfToken() {
    return document.querySelector("meta[name='csrf-token']").content;
  }

}
