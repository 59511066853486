import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="calc-test--log-viewer"
export default class extends Controller {
  static targets = ["loading", "dest", "logFragment", "block"];
  static values = {
    userInterfaceVersion: {type: String, default: "application"},
    source: {type: String, default: ""},
    simulationLabel: {type: String, default: "Simulation"},
    solverLabel: {type: String, default: "Solver"},
    itemLabel: {type: String, default: "Item"}
  };

  initialize() {
    this.elementMap = {};
  }

  logFragmentTargetConnected(element) {
    this.generateLog(element.getAttribute("data-log-fragment"));
    if (this.hasLoadingTarget) {
      this.loadingTarget.classList.add("hidden");
    }
  }

  matchRule(text) {
    // Look for rules pattern in the log messages
    for (const regexRules of text.matchAll(/(?:^|\s|\()([\w\d]+(?:\/[\.\-\+\$\§\_ºª=A-zÀ-ÿ\d|-]*){2,13}:[^\):]+)(?:\))/g)) {
      let rule = regexRules[1];
      text = text.replaceAll(rule, `<a href="/show/rule?code=${encodeURIComponent(rule)}" target="_blank">${rule}</a>`);
    }
    return text;
  }

  generateLog(logFragment) {
    const isNewLog = logFragment.match(/(?:\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}(?:\.\d+)?)\|(?:([^\|\n]+))+\|(?:([^\|\n]+))+/);
    if (!isNewLog) {
      let logArray = logFragment.split("\n");
      for (const line in logArray) {
        this.appendText(logArray[line], this.destTarget);
      }
      return;
    }

    // Match the new regex pattern to the log and process every match
    for (const result of logFragment.matchAll(/(?:\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}(?:\.\d+)?)\|(?:([^\|\n]+))+\|(?:([^\|\n]*))+/gm)) {
      let textToAppend = result[2];
      if (result[2].startsWith("[Inicio") || result[2].startsWith("[Fim")) continue;
      if (result[2].startsWith("Início: ") || result[2].startsWith("Fim: ")) {
        textToAppend = `<b>${result[2]}</b>`;
      }
      if (`#telv-${result[1]}` in this.elementMap) {
        this.appendText(textToAppend, this.elementMap[`#telv-${result[1]}`]);
      } else {
        const stopList = ["Calculo", "Pre_Calculo", "Pos_Calculo", "Simulacao", "Solver", "Recalculo_", "Item_", "Totalizador", "A_"];
        const rootValues = ["Calculo", "Pre_Calculo", "Pos_Calculo", "Totalizador"];
        const divStructure = result[1].split("#").filter(item => stopList.some(word => item.startsWith(word)));
        const fromTo = {
          "Simulacao": `${this.simulationLabelValue} `,
          "Solver": `${this.solverLabelValue} `,
          "Item": `${this.itemLabelValue} `
        };
        let targetElement = this.destTarget;
        let currentElement = "telv";

        for (const newElement of divStructure) {
          currentElement += `-${newElement}`;
          if (!(currentElement in this.elementMap)) {
            if (rootValues.some(stop => newElement == stop)) {
              this.elementMap[currentElement] = targetElement;
            } else {
              let isDiv = newElement.startsWith("A_") || newElement.startsWith("Item_") || newElement.startsWith("Recalculo_");
              let element = document.createElement("details");
              if (isDiv) {
                element = document.createElement("div");
                element.classList.add("log-imposto-item");
              }
              let summaryTextArray = newElement.split("_");
              let summaryText = "";
              element.id = currentElement;
              element.title = currentElement.replace("telv-", "")
                                            .replaceAll("-", "➞")
                                            .replaceAll("_", " ");
              // element.classList.add("box");
              element.setAttribute("data-calc-test--log-viewer-target", "block");

              let summary = document.createElement("summary");
              summary.classList.add("font-weight-bold");
              summary.innerText = result[2].replace("Início: ", "")
                                           .replace("Fim: ", "");

              if (summary.innerText.indexOf("Solver") >= 0 || summary.innerText.indexOf("Item") >= 0) {
                if (summaryTextArray.length == 1) {
                  summaryText = summaryTextArray[0].replace(summaryTextArray[0], fromTo[summaryTextArray[0]]);
                } else {
                  summaryText = `${summaryTextArray[0].replace(summaryTextArray[0], fromTo[summaryTextArray[0]])} ${summaryTextArray[1]}`;
                }
                summary.innerText = summaryText;
              }

              if (!isDiv) element.append(summary);

              this.elementMap[currentElement] = element;
              targetElement.append(element);
              targetElement = element;
            }
          } else {
            targetElement = this.elementMap[currentElement];
          }
        }
        this.appendText(textToAppend, targetElement);
      }
    }
  }

  appendText(textToAppend, target) {
    let p = document.createElement("p");
    let text = this.matchRule(textToAppend);
    p.innerHTML = text;
    target.append(p);
  }

  expandAll() {
    if (!("onbeforematch" in document.body)) {
      this.blockTargets.forEach(block => {
        block.setAttribute("open", true);
        // block.classList.add("in", "show");
      });
    }
  }
}
