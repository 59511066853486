import React from 'react';

import Button from '@material-ui/core/Button';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';

const useStylesActionMenu = makeStyles({
  root: {
    marginBottom: 20,
  },
});

function ActionMenu() {
  const classes = useStylesActionMenu();

  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div className={classes.root}>
      <Button
        variant="outlined"
        endIcon={<MoreVertIcon />}
        onClick={handleClick}
      >
        Ações
      </Button>
      <Menu
        getContentAnchorEl={null}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <MenuItem onClick={handleClose}>Baixar planilha modelo</MenuItem>
        <MenuItem onClick={handleClose}>Nova importação</MenuItem>
      </Menu>
    </div>
  );
}

export default ActionMenu