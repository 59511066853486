// Rails
import "@hotwired/turbo-rails";
// Devido a ter mais códigos legados que quebram com o uso do turbo
// ele será desabilitado por padrão e onde for utilizado
// deverá adicionar data: {turbo true}, assim que o sistema for refatorado
// para compatibilidade com o turbo, essa linha deverá ser removida.
Turbo.session.drive = false;
import "controllers";
import "channels";
import Trix from "trix";
import "@rails/actiontext";
import Rails from "@rails/ujs";
import "jquery";
import "datatables.net";
import "datatables.net-bs";
import "@fortawesome/fontawesome-free/css/all";
import "material-symbols";
import "../stylesheets/material-symbols.scss";
import "bootstrap";
import "bootstrap/dist/css/bootstrap";
import "../stylesheets/bootstrap-4-utilities.min";
import "gridstack/dist/gridstack.min.css";
import "gridstack/dist/gridstack-extra.min.css";
import TurboConfirm from "@plugins/turbo-confirm";
import mermaid from "mermaid";
import { defineCustomElements as RevogridLoader } from "@revolist/revogrid/loader";

// Libs
Rails.start();
TurboConfirm.start();
RevogridLoader();
mermaid.initialize({
  startOnLoad: false,
  theme: "forest"
});

$(document).on("change", "select[data-selected-form-id]", function (ev) {
  let $el = $(ev.target);
  let formID = $el.attr("data-selected-form-id");
  let formEL = document.getElementById(formID);

  $(formEL).append(`<input id="input_hidden_form_selected" type="hidden" name="per_page" value="${$el.val()}"></input>`);
  $(formEL).append(`<input id="button_submit_form_selected" type="submit" name="commit" value="submit">`);

  let $button_to_selected = $("#button_submit_form_selected");
  $button_to_selected.click();

  $("#input_hidden_form_selected").remove();
  $button_to_selected.remove();
});


document.addEventListener("turbo:load", function (_event) {
  if ($(".mermaid").length > 0) {
    mermaid.init(undefined, ".mermaid");
  }

  if ($("a[data-submit-form-id]").length > 0) {
    $("a[data-submit-form-id]").click(function (ev) {
      ev.preventDefault();
      var formID = $(ev.target).attr("data-submit-form-id");
      var formEL = document.getElementById(formID);
      $(formEL).trigger("submit.rails");
    });
  }

  if ($("#model_table").length > 0) {
    $("#model_table").DataTable({
      paging: true,
      retrieve: true,
      responsive: true,
      language: {
        url: "//cdn.datatables.net/plug-ins/1.10.25/i18n/Portuguese-Brasil.json"
      }
    });
  }

  if ($("#info_alias_grid").length > 0) {
    $("#info_alias_grid").DataTable({
      retrieve: true,
      paging: true,
      responsive: true,
      language: {
        url: "//cdn.datatables.net/plug-ins/1.10.25/i18n/Portuguese-Brasil.json"
      }
    });
  }

});

//DEBUG EVENT
// $(document).on('monitor-legal-legal-acts-show--modal-closed', ev => console.log(ev))

// React Components
// Support component names relative to this directory:
var componentRequireContext = require.context("components", true);
var ReactRailsUJS = require("react_ujs");
ReactRailsUJS.useContext(componentRequireContext);

import { defineCustomElements } from "@revolist/revogrid/loader/index.es2017.js";

defineCustomElements();