import { Controller } from "@hotwired/stimulus";
import { SwalConfirm } from "@plugins/sweetalert2";

// Connects to data-controller="accounts--icms-tax-rates--search"
export default class extends Controller {
  static outlets = ["checkbox"];
  static targets = ["form"];
  static values = {confirmationLabel: {type: String, default: "Are you sure?"}};
  search(_event) {
    this.formTarget.requestSubmit();
  }

  confirmSubmit(event) {
    event.preventDefault();

    if (this.hasCheckboxOutlet === false) return;
    if (this.checkboxOutlet.totalChecked > 0 || this.checkboxOutlet.checkboxAllChecked || this.checkboxOutlet.checkboxSearchAllChecked) {
      let that = this;
      SwalConfirm.fire({
        html: this.confirmationLabelValue
      }).then(action => {
        if (action.isConfirmed) {
          that.checkboxOutlet.clearCheckedValues();
          that.checkboxOutlet.clearAllSearch();
          that.formTarget.requestSubmit();
        }
      });
    }
  }
}
