import React, {useState } from 'react';
import { useConfirm } from 'material-ui-confirm';
import { useSnackbar } from 'notistack';

import IconButton from '@material-ui/core/IconButton';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import MoreVertIcon from '@material-ui/icons/MoreVert';

function TableItemMenu(props) {
  const {
    row,
    onDelete,
    onDownload
  } = props

  const { enqueueSnackbar, _closeSnackbar } = useSnackbar();
  const confirm = useConfirm();
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = _ => {
    setAnchorEl(null);
  };

  const handleDownload = apiUrl => {
    onDownload(apiUrl)
    handleClose()
  }

  const handleDelete = apiUrl => {
    handleClose()    
    confirm({description: 'Deseja excluir esse registro?'})
      .then(_ => {
        onDelete(apiUrl)
      })
      .catch(_ => {})
  }

  return (
    <>
      <IconButton
        id={`btn-menu-${row.protocol}`}
        onClick={handleClick}
        size="small"
      >
        <MoreVertIcon />
      </IconButton>
      <Menu
        getContentAnchorEl={null}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        {
          row.status == 'finished' && !row.error &&
          <MenuItem
            id={`btn-menu-download-results-${row.protocol}`}
            onClick={_ => handleDownload(row.links.api_download_result_url)}
          >
            Baixar planilha de resultados calculados
          </MenuItem>
        }
        {
          row.status == 'finished' &&
          <MenuItem
            id={`btn-menu-download-log-${row.protocol}`}
            onClick={_ => handleDownload(row.links.api_download_log_url)}
          >
            Baixar log do processo
          </MenuItem>
        }
        <MenuItem
          id={`btn-menu-delete-log-${row.protocol}`}
          onClick={_ => handleDelete(row.links.api_data_url)}
        >
          Excluir
        </MenuItem>
      </Menu>
    </>
  );
}

export default TableItemMenu;