import React, { forwardRef } from "react";
import PropTypes from "prop-types";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Slide from "@material-ui/core/Slide";
import ReactHtmlParser from 'react-html-parser';

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function AlertDialogSlide(props) {
  const {
    title,
    contentText,
    isOpen,
    showFullScreen,
    onClose,
    onConfirm,
    labelClose,
    labelConfirm,
  } = props;

  return (
    <div>
      <Dialog
        open={isOpen}
        fullScreen={showFullScreen}
        TransitionComponent={Transition}
        keepMounted
        scroll="paper"
        onClose={onClose}
      >
        <DialogTitle id="scroll-dialog-title">{title}</DialogTitle>
        {contentText && (
          <DialogContent dividers={true}>
            { ReactHtmlParser(contentText) }
          </DialogContent>
        )}
        <DialogActions>
          {onClose && (
            <Button onClick={onClose} color="default">
              {labelClose}
            </Button>
          )}
          {onConfirm && (
            <Button onClick={onConfirm} color="primary">
              {labelConfirm}
            </Button>
          )}
        </DialogActions>
      </Dialog>
    </div>
  );
}

AlertDialogSlide.propTypes = {
  title: PropTypes.string.isRequired,
  contentText: PropTypes.string,
  isOpen: PropTypes.bool,
  showFullScreen: PropTypes.bool,
  onClose: PropTypes.func,
  onConfirm: PropTypes.func,
  labelClose: PropTypes.string,
  labelConfirm: PropTypes.string,
};

AlertDialogSlide.defaultProps = {
  isOpen: false,
  showFullScreen: false,
  labelClose: "Cancel",
  labelConfirm: "Confirm",
};

export default AlertDialogSlide;
