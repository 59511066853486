import { Controller } from "@hotwired/stimulus"
import { toggleVisible } from "./utils/visibility";

export default class extends Controller {
  static targets = [
    "checkboxAll", "checkbox",
    "selectionInfo", "selectionInfoSearch",
    "selectionInfoCheckbox", "selectionInfoCheckboxCount",
    "selectAllRow", "checkboxSearchAll",
    "clearValuesLink", "pageSelectedMessage",
    "selectAllLink", "allSelectedMessage",
    "clearSearchLink", "searchParamValues"
  ]
  static values = {
    checked: { type: Array, default: [] }
  }

  connect() {
    this.checkboxStateChanged()
  }

  initWithState(state) {
    // console.log("initState", state)
    const { pageValues, searchAllChecked, searchParamValues } = state

    this.checkedValue = pageValues
    if (this.hasCheckboxSearchAllTarget) this.checkboxSearchAllTarget.checked = searchAllChecked
    if (this.hasCheckboxAllTarget) this.checkboxAllTarget.checked = searchAllChecked
    if (pageValues.size === 0 && this.hasSearchParamValuesTarget) this.searchParamValuesTarget.value = searchParamValues

    this.checkboxes.forEach(ckbox => ckbox.checked = pageValues.includes(ckbox.value))
    this._setAllCheckboxToIndeterminate(searchAllChecked)
    this.refreshCheckboxState()
  }

  checkboxSearchAllTargetConnected(_element) {
    if (this.hasCheckboxSearchAllTarget === false) return

    toggleVisible(this.checkboxSearchAllTarget, this.checkboxSearchAllChecked)
  }

  selectAllRowTargetConnected(_element) {
    this._setSelectAllRowVisibilityTo(this.checkboxSearchAllChecked)
  }

  checkedValueChanged() {
    if (this.hasSelectionInfoCheckboxCountTarget) {
      this.selectionInfoCheckboxCountTarget.innerHTML = this.totalChecked
    }
  }

  checkAll() {
    if (this.hasCheckboxAllTarget === false) return

    this.checkboxes.forEach(ckbox => {
      ckbox.checked = this.checkboxAllChecked
      ckbox.indeterminate = false
      this._updateCheckedValue(ckbox.value, this.checkboxAllChecked)
    })
    this.refreshCheckboxState()
  }

  checkOne(event) {
    if (this.hasCheckboxTarget === false) return

    const { value, checked } = event.currentTarget
    this._updateCheckedValue(value, checked)
    this.refreshCheckboxState()
  }

  selectAllSearch() {
    if (this.hasCheckboxSearchAllTarget === false) return

    this.checkboxSearchAllTarget.checked = true
    this.checkedValue = []
    this._setAllCheckboxToIndeterminate(true)
    this.refreshCheckboxState()
  }

  clearAllSearch() {
    if (this.hasCheckboxSearchAllTarget === false) return

    this.checkboxSearchAllTarget.checked = false
    this._setAllCheckboxToIndeterminate(false)
    this.refreshCheckboxState()
  }

  clearCheckedValues(event) {
    if (event) event.preventDefault();
    this.checkedValue = []

    this._setCheckAllStateTo(false)
    this.checkboxes.forEach(ckbox => {
      ckbox.checked = false
      ckbox.indeterminate = false
    })
    this.refreshCheckboxState()
  }

  refreshCheckboxState() {
    let { checked, indeterminate} = this.countCheckboxState

    if (checked > 0 && indeterminate > 0) {
      this._setAllCheckboxToIndeterminate(false)
      indeterminate = 0
    }

    if (indeterminate === this.countCheckbox) {
      this._setCheckAllStateTo(false, true)
      this._setSelectAllRowVisibilityTo(true)
    } else if (checked === this.countCheckbox) {
      this._setCheckAllStateTo(true)
      this._setSelectAllRowVisibilityTo(true)
    } else {
      this._setCheckAllStateTo(false)
      this._setCheckboxSearchAllStateTo(false)
      this._setSelectAllRowVisibilityTo(false)
    }

    this._toggleSelectionInfo(this.checkboxSearchAllChecked, this.totalChecked)
    this._toggleAllSelected(this.checkboxSearchAllChecked)
    this.checkboxStateChanged()
  }

  checkboxStateChanged() {
    let detailPayload = {
      pageValues: this.checkedValue
    }

    if (this.hasCheckboxAllTarget) detailPayload.pageAllChecked = this.checkboxAllChecked
    if (this.hasCheckboxSearchAllTarget) detailPayload.searchAllChecked = this.checkboxSearchAllChecked
    if (this.hasSearchParamValuesTarget) detailPayload.searchParamValues = this.searchParamValuesTarget.value

    // console.log("checkboxStateChanged", detailPayload)
    this.dispatch("state-changed", {
      detail: detailPayload
    })
  }

  _setCheckAllStateTo(checked, indeterminate=false) {
    if (this.hasCheckboxAllTarget === false) return

    this.checkboxAllTarget.checked = checked
    this.checkboxAllTarget.indeterminate = indeterminate
  }

  _setCheckboxSearchAllStateTo(checked, indeterminate=false) {
    if (this.hasCheckboxSearchAllTarget === false) return

    this.checkboxSearchAllTarget.checked = checked
    this.checkboxSearchAllTarget.indeterminate = indeterminate
  }

  _setSelectAllRowVisibilityTo(visible) {
    if (this.hasSelectAllRowTarget === false) return

    toggleVisible(this.selectAllRowTarget, visible)
  }

  _toggleSelectionInfo(searchAll=false, totalChecked=0) {
    if (this.hasSelectionInfoTarget === false) return;

    if (this.hasClearValuesLinkTarget) toggleVisible(this.clearValuesLinkTarget, searchAll || totalChecked > 0)
    if (this.hasSelectionInfoSearchTarget) toggleVisible(this.selectionInfoSearchTarget, searchAll)
    if (this.hasSelectionInfoCheckboxTarget) toggleVisible(this.selectionInfoCheckboxTarget, !searchAll)
  }

  _toggleAllSelected(show=false) {
    if (this.hasSelectAllRowTarget === false) return;

    if (this.hasPageSelectedMessageTarget)  toggleVisible(this.pageSelectedMessageTarget, !show)
    if (this.hasSelectAllLinkTarget)  toggleVisible(this.selectAllLinkTarget, !show)
    if (this.hasAllSelectedMessageTarget)  toggleVisible(this.allSelectedMessageTarget, show)
    if (this.hasClearSearchLinkTarget)  toggleVisible(this.clearSearchLinkTarget, show)
  }

  _updateCheckedValue(value, checked) {
    const setValues = new Set(this.checkedValue);
    checked ? setValues.add(value) : setValues.delete(value)
    this.checkedValue = Array.from(setValues);
  }

  _setAllCheckboxToIndeterminate(indeterminate) {
    if (this.hasCheckboxTarget === false) return;

    this.checkboxes.forEach(ckbox => {
      ckbox.indeterminate = indeterminate
      if (indeterminate) ckbox.checked = false
    })
  }

  get totalChecked() {
    return this.checkedValue.length
  }

  get checkboxes() {
    if (this.countCheckbox === 0) return []

    return this.checkboxTargets
  }

  get countCheckbox() {
    if (this.hasCheckboxTarget === false || this.checkboxTargets.length === 0) return 0

    return this.checkboxTargets.length
  }

  get countCheckboxState() {
    let checked = 0, indeterminate = 0

    this.checkboxTargets.forEach(ckbox => {
      if (ckbox.checked) checked++
      if (ckbox.indeterminate) indeterminate++
    })

    return { checked, indeterminate }
  }

  get checkboxAllChecked() {
    if (this.hasCheckboxAllTarget === false) return false

    return this.checkboxAllTarget.checked
  }

  get checkboxSearchAllChecked() {
    if (this.hasCheckboxSearchAllTarget === false) return false

    return this.checkboxSearchAllTarget.checked
  }

}
// Connects to data-controller="checkbox"
