import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="scroll-navigator"
export default class extends Controller {
  static targets = ["prevButton", "nextButton"];
  static values = {
    elementOffset: {type: String, default: ""},
    stopPointSelector: {type: String, default: ".scroll-stop-point"},
    stopPointClass: {type: String, default: "stopped-point"},
    containerSelector: {type: String, default: "body"},
    suffix: {type: String, default: (Math.random() + 1).toString(36).substring(7)}
  };

  prev() {
    const targetElement = this.prevElement;
    if (this._isNull(targetElement)) return this._scrollTo(this.containerElement);

    this.stopPoint = targetElement;
  }

  next() {
    const targetElement = this.nextElement;
    if (this._isNull(targetElement)) return this._scrollTo(this.containerElement);

    this.stopPoint = targetElement;
  }

  /* *** GETTERS *** */

  get prevElement() {
    let element = this.stoppedPointElement;
    const visibleElements = this.visiblePointElements;
    const lastElementIndex = visibleElements.length - 1;
    if (visibleElements.length === 0) return null;

    if (this._isNull(element)) {
      element = visibleElements[lastElementIndex];
    } else {
      const curIndex = visibleElements.findIndex(curEl => curEl === element);
      const prevIndex = curIndex - 1;
      element = visibleElements.at(prevIndex < 0 ? lastElementIndex : prevIndex);
    }
    return element;
  }

  get nextElement() {
    let element = this.stoppedPointElement;
    const visibleElements = this.visiblePointElements;
    if (visibleElements.length === 0) return null;

    if (this._isNull(element)) {
      element = visibleElements[0];
    } else {
      const curIndex = visibleElements.findIndex(curEl => curEl === element);
      const nextIndex = curIndex + 1;
      element = visibleElements.at(nextIndex >= visibleElements.length ? 0 : nextIndex);
    }
    return element;
  }

  get containerElement() {
    if (this._containerElement === undefined) {
      const elContainer = document.querySelector(`${this.containerSelectorValue}`);
      this._containerElement = this._isNull(elContainer) ? document.querySelector("body") : elContainer;
    }
    return this._containerElement;
  }

  get visiblePointElements() {
    return Array.from(this.stopPointElements).filter(element => this._isVisible(element));
  }

  get stopPointElements() {
    return this.containerElement.querySelectorAll(`${this.stopPointSelectorValue}`);
  }

  get stoppedPointElement() {
    return this.containerElement.querySelector(`${this.stopPointSelectorValue}.${this.stopPointClassValue}`);
  }

  get elementOffsetHeight() {
    const element = document.querySelector(this.elementOffsetValue);
    if (this._isNull(element)) return 0;

    return this._absoluteHeight(element);
  }

  /* *** SETTERS *** */

  set stopPoint(element) {
    if (this._isNull(element)) {
      return;
    }
    this._clearStoppedPoints();
    this._scrollTo(element);
    element.classList.add(this.stopPointClassValue);
  }

  /* *** PRIVATE *** */

  _scrollTo(element) {
    const top = (element.getBoundingClientRect().top + window.pageYOffset) - this.elementOffsetHeight;
    window.scrollTo({top: top, behavior: "smooth"});
  }

  _clearStoppedPoints() {
    this.containerElement.querySelectorAll(this.stopPointSelectorValue).forEach(el => el.classList.remove(this.stopPointClassValue));
  }

  _isNull(test) {
    return ((typeof test === "undefined") || test === null);
  }

  _isVisible(element) {
    const style = window.getComputedStyle(element);
    return style.display !== "none" && style.visibility !== "hidden" && element.classList.contains("hidden") === false;
  }

  _absoluteHeight(element) {
    element = (typeof element === "string") ? document.querySelector(element) : element;
    const styles = window.getComputedStyle(element);
    const margin = parseFloat(styles["marginTop"]) + parseFloat(styles["marginBottom"]);
    return Math.ceil(element.offsetHeight + margin);
  }
}