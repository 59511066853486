import {Controller} from "@hotwired/stimulus"

// Connects to data-controller="common-form"
export default class extends Controller {
  static values = {
    reference: {type: String, default: "no-reference"}
  }

  connect() {
    const that = this
    this.element.addEventListener('turbo:submit-end', (event) => {
      if (event.detail.success) {
        that.dispatch("submitted")
        that.dispatch(`${this.referenceValue}--submitted`)
      }
    })
  }
}
