import React, { useState, useEffect } from 'react';
import PropTypes from "prop-types";
import { LazyLog } from 'react-lazylog';
import axios from "axios";
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
  line: {
    margin: '0 !important',
    '& a:first-child': {
      minWidth: '10px',
      paddingRight: 0,
      width: 'auto',
      textDecoration: 'none',
      color: '#d6d6d6',
    },
    '& span:first-child': {
      paddingRight: '10px', 
    }
  },
});

function LogViewer(props) {
  const {
    dataUrl,
    loadingLabel
  } = props;
  const [loading, setLoading] = useState(false);
  const [content, setContent] = useState(null);
  const style = useStyles();

  const getContent = _ => {
    setLoading(true);
    return axios
      .get(dataUrl)
      .then(response => {
        // console.log(response);
        const items = response.data;
        setContent(items.length > 0 ? items.join("\n") : null);
      })
      .catch(error => {
        // console.log("error", error);
        setContent(error)
      }).then(() => setLoading(false));
  }

  useEffect(() => {
    getContent();
  }, []);

  return <>
    {loading && <div>{loadingLabel}</div>}
    {content && <LazyLog
      height={300}
      style={{
        padding: '10px 0px',
        color: 'rgb(248, 248, 242)',
        backgroundColor: 'rgb(39, 40, 34)'
      }}
      follow={true}
      // onHighlight={(e) => alert(e) }
      lineClassName={style.line}
      text={content}
      selectableLines
    />}
  </>
}

LogViewer.propTypes = {
  dataUrl: PropTypes.string.isRequired,
  loadingLabel: PropTypes.string
};

LogViewer.defaultProps = {
  loadingLabel: "Loading..."
};

export default LogViewer;