import React from 'react'

import Box from '@material-ui/core/Box';
import LinearProgress from '@material-ui/core/LinearProgress';
import Chip from '@material-ui/core/Chip';
import DoneIcon from '@material-ui/icons/Done';
import ScheduleIcon from '@material-ui/icons/Schedule';
import ErrorIcon from '@material-ui/icons/ErrorOutline';

function RecordStatus({status, error, time}) {
  const hasError = typeof(error) !== 'undefined' && error !== null && error.toString() !== '';
  const ChipStatus = ({label, icon, color='default'}) => (
    <Chip label={label} variant="outlined" icon={icon} color={color} size="small" />
  )
  const translatedStatus = _ => {
    if (status == 'waiting') return <ChipStatus label="Aguardando" icon={<ScheduleIcon />} />
    if (status == 'running') return 'Executando'
    if (status == 'finished' && hasError) return <ChipStatus label="Falhou" icon={<ErrorIcon />} color="secondary" />
    if (status == 'finished') return <ChipStatus label="Finalizado" icon={<DoneIcon />} color="primary" />
    return 'Desconhecido'
  }

  const RecordStatusText = _ => {
    return (
      <Box
        display="flex"
        direction="row"
        justifyContent="space-between"
        alignItems="center"
      >
        <Box data-status={status}>{translatedStatus()}</Box>
        <Box data-time={time} pl={1} style={{whiteSpace: 'nowrap'}}>{time}</Box>
      </Box>
    )
  }

  const RecordStatusProgress = _ => {
    // if (status == 'waiting')
    //   return <LinearProgress variant="buffer" value={0} valueBuffer={0} />
    if (status == 'running')
      return <LinearProgress />
    return null
  }
  
  return (<>
    <RecordStatusText />
    <RecordStatusProgress />
  </>)

}

export default RecordStatus;