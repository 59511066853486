
import React from 'react';
import PropTypes from "prop-types";
import { makeStyles } from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';

import { green } from '@material-ui/core/colors';
import ErrorIcon from '@material-ui/icons/Error';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';

const tooltipStyles = makeStyles(theme => ({
  tooltip: {
    fontSize: '12pt',
    backgroundColor: theme.palette.common.black,
  },
}));


function RuntimeStatusIcon(props) {
  const {status} = props;
  const classes = tooltipStyles();
  
  return (
    <Tooltip classes={classes} disableFocusListener title={status}>
      {
        status.toString().toLowerCase() == 'ok' ? 
        <CheckCircleIcon style={{ color: green[500] }} fontSize="large" /> :
        <ErrorIcon color='error' fontSize="large" />
      }
    </Tooltip>
  );
}

// RuntimeStatusIcon.propTypes = {
//   status: PropTypes.string.isRequired,
// };

RuntimeStatusIcon.defaultProps = {
  status: ''
};

export default RuntimeStatusIcon;