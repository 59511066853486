import {Controller} from "@hotwired/stimulus"

// Connects to data-controller="tax-law-pautas--exncm-search"
export default class extends Controller {

    connect() {
    }


    updateTaxLawPautaTable(e) {
        let rowId =  this.element.dataset.id;
        let update = {}
        update[rowId] = [{field:'exncm', value:this.element.dataset.exncm},
                          {field:'full_description', value:this.element.dataset.description}]
        document.dispatchEvent(new CustomEvent('updateDinamicTable', {
            detail: update
        }))
    }


}
