import { Controller } from "@hotwired/stimulus";
import TextHighlighter from "text-highlighter";

// Connects to data-controller="simple-list-filter"
export default class extends Controller {
  static targets = ["searchInput", "listItem"];
  static values = {
    keyword: {type: String, default: "keyword"}
  };

  connect() {
    if (this.hasSearchInputTarget) {
      this.searchInputTarget.addEventListener("input", this._handleOnInput.bind(this));
    }
  }

  _handleOnInput(event) {
    const inputValue = event.target.value.toLowerCase().trim();
    const regex = new RegExp(inputValue, "i");
    if (this.hasListItemTargets === false) return;

    this.listItemTargets.forEach(element => {
      const keyword = element.getAttribute(`data-${this.keywordValue}`);
      const textHighlighter = new TextHighlighter(element);
      if (regex.test(keyword)) {
        element.classList.remove("hidden");
        textHighlighter.highlight(inputValue);
      } else {
        textHighlighter.dehighlight();
        element.classList.add("hidden");
      }
    });
  }

  disconnect() {
    // console.log("disconnected!");
  }
}
