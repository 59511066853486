import { Controller } from "@hotwired/stimulus"


// Connects to data-controller="monitor-legal--legal-acts--relevance"
export default class extends Controller {
  static targets = ["input"];

  connect() {
    const that = this;
    this.element.addEventListener('turbo:submit-end', (event) => {
      if (event.detail.success) {
        that.dispatch("relevance-updated")
      }
    })
  }

  select(event) {
    this.inputTarget.value = event.target.getAttribute("data-relevance");
    this.submit()
  }

  submit() {
    this.element.requestSubmit()
  }
}
