import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="checkbox-state"
export default class extends Controller {
  static outlets = [ "checkbox" ]
  static values = {
    checkedPage: { type: Boolean, default: false },
    valuesPage: { type: Array, default: [] },
    checkedSearch: { type: Boolean, default: false },
    paramsSearch: { type: String, default: '' },
  }

  checkboxOutletConnected(outlet, element) {
    // load searchParams
    if (this.paramsSearchValue === '' && outlet.hasSearchParamValuesTarget) {
      this.paramsSearchValue = outlet.searchParamValuesTarget.value
    }

    outlet.initWithState({
      pageAllChecked: this.checkedPageValue,
      pageValues: this.valuesPageValue,
      searchAllChecked: this.checkedSearchValue,
      searchParamValues: this.paramsSearchValue
    })

    element.addEventListener('checkbox:state-changed', ({ detail }) => {
      // console.log("state-changed", detail)
      this.checkedPageValue = detail.pageAllChecked
      this.valuesPageValue = detail.pageValues
      this.checkedSearchValue = detail.searchAllChecked
      this.paramsSearchValue = detail.searchParamValues
    })
  }
}
