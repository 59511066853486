import { Controller } from "@hotwired/stimulus";
import { Chart } from "../../../vendor/mosaic_css/js/mdb.pro";
import { getColorOfTax } from "../../mdb/utils/colors";

// Connects to data-controller="accounts--simulations--tax-simulation-chart"
export default class extends Controller {
  static values = {
    type: {type: String, default: "bar"},
    legendDisplay: {type: Boolean, default: true},
    legendPosition: {type: String, default: "bottom"},
    labels: {type: Array, default: []},
    datasets: {type: Array, default: []},
    title: {type: String, default: ""},
    subtitle: {type: String, default: ""}
  };

  mountDataset(dataset) {
    let ds = {
      label: dataset.label,
      data: dataset.data,
      stack: dataset.stack,
      order: dataset.order,
      backgroundColor: getColorOfTax(dataset.label)
    }

    if (dataset.type === 'line') {
      ds.type = dataset.type
      ds.borderWidth = 1
      ds.lineTension = 0.0
      ds.borderColor = '#2e5ad2'
      ds.backgroundColor = '#2e5ad2'
      ds.pointRadius = 2
    }

    return ds
  }

  connect() {
    this._createCanvas();

    const datasets = this.datasetsValue.map((dataset) => (
      this.mountDataset(dataset)
    ));

    new Chart(this._canvas, {
        type: this.typeValue,
        data: {
          labels: this.labelsValue,
          datasets: datasets
        }
      },
      {
        options: {
          barPercentage: 0.6,
          plugins: {
            title: {
              display: true,
              text: this.titleValue,
              font: {
                size: 13
              },
              color: '#072a51',
              align: 'start'
            },
            subtitle: {
              display: true,
              text: this.subtitleValue,
              font: {
                size: 13
              },
              color: '#072a51',
              padding: {
                bottom: 20
              },
              align: 'start'
            },
            legend: {
              position: 'bottom'
            },
            tooltip: {
              callbacks: {
                label: function (tooltipItem) {
                  let value = tooltipItem.raw;
                  return new Intl.NumberFormat('pt-BR', {style: 'currency', currency: 'BRL'}).format(value);
                }
              }
            }
          },
          responsive: true,
          scales: {
            y: {
              stacked: true,
              ticks: {
                callback: function(value) {
                  let numericValue = Number(value);
                  return 'R$ ' + numericValue.toLocaleString('pt-BR', { minimumFractionDigits: 2 });
                }
              }
            },
            x: {
              stacked: true
            }
          }
        }
      });
  }

  disconnect() {
    const mdbChart = Chart.getInstance(this._canvas);
    mdbChart.dispose();
  }

  _createCanvas() {
    if (this._canvas) return;
    if (this.element.nodeName === "CANVAS") {
      this._canvas = this.element;
    } else {
      this._canvas = document.createElement("canvas");
      this.element.appendChild(this._canvas);
    }
  }
}
