import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="monitor-legal--legal-acts--classified-actions"
export default class extends Controller {
  static values = {
    working: Boolean,
    url: String
  }

  connect() {
    if (this.workingValue) {
      window.setTimeout(_ => this.refresh(), 5000)
    }
  }

  refresh() {
    fetch(this.urlValue)
    .then (response => response.text())
    .then(html => Turbo.renderStreamMessage(html));
  }
}
