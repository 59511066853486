import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="mosaic-bs--sidenav"
export default class extends Controller {
  static values = {
    reference: {type: String, default: "no-reference"},
    toggleButton: {type: String, default: ""}
  };

  connect() {
    this._addToggleButtonListener();
  }

  show() {
    this.element.classList.remove("closed");
    this.element.classList.add("open");
  }

  hide() {
    this.element.classList.remove("open");
    this.element.classList.add("closed");
  }

  toggle() {
    if (this.element.classList.contains("open")) {
      this.hide();
    } else {
      this.show();
    }
  }

  disconnect() {
    this._removeToggleButtonListener();
  }

  get toggleButtons() {
    if (this.toggleButtonValue === "") return [];

    return Array.from(document.querySelectorAll(this.toggleButtonValue));
  }

  _handleToggleButtonClick(event) {
    event.preventDefault();
    this.toggle();
  }

  _addToggleButtonListener() {
    const that = this;
    this.toggleButtons.forEach(btn => btn.addEventListener("click", this._handleToggleButtonClick.bind(that)));
  }

  _removeToggleButtonListener() {
    const that = this;
    this.toggleButtons.forEach(btn => btn.removeEventListener("click", this._handleToggleButtonClick.bind(that)));
  }
}