import React, { useEffect, useState } from 'react';
import axios from "axios";

import { useDropzone } from 'react-dropzone';
import { useSnackbar } from 'notistack';

import { makeStyles } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import List from '@material-ui/core/List';
import UploadIcon from '@material-ui/icons/CloudUpload';
import Box from '@material-ui/core/Box';
import Skeleton from '@material-ui/lab/Skeleton';
import filesize from "filesize";
import ListItem from '@material-ui/core/ListItem';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import RemoveIcon from '@material-ui/icons/RemoveCircleOutlineOutlined';
import ClearAllIcon from '@material-ui/icons/ClearAll';
import LinearProgress from '@material-ui/core/LinearProgress';
import CloseIcon from '@material-ui/icons/Close';

import AutoCompleteRemote from './AutoCompleteRemote';

const useStyles = makeStyles(theme  => ({
  drawerPaper: {
    width: '450px',
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
  dropzone: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '20px',
    borderWidth: '2px',
    borderRadius: '2px',
    borderColor: '#eeeeee',
    borderStyle: 'dashed',
    backgroundColor: '#fafafa',
    color: '#bdbdbd',
    outline: 'none',
    transition: 'border .24s ease-in-out',
    textAlign: 'center' 
  },
  toolbar: {
    borderBottom: `1px solid ${theme.palette.divider}`,
    padding: theme.spacing(1, 3),
  }
}))

function DrawerUpload(props) {
  const {
    uploadUrl,
    componentVersionsUrl,
    queryScope,
    onClose,
    open
  } = props;

  const { enqueueSnackbar, _closeSnackbar } = useSnackbar();
  const classes = useStyles();
  const [files, setFiles] = useState([]);
  const [uploadProgress, setUploadProgress] = useState(0);
  const [uploading, setUploading] = useState(false);
  const [loading, setLoading] = useState(false);
  const [logicsVersions, setLogicsVersions] = useState([]);
  const [rulesVersions, setRulesVersions] = useState([]);
  const [deltasVersions, setDeltasVersions] = useState([]);
  const [logicVersion, setLogicVersion] = useState();
  const [ruleVersion, setRuleVersion] = useState();
  const [deltaVersion, setDeltaVersion] = useState();

  const {getRootProps, getInputProps, acceptedFiles} = useDropzone({
    accept: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    disabled: uploading || !logicVersion || !ruleVersion,
    noKeyboard: true
  });

  const handleRemoveFile = file => {
    const newFiles = [...files]
    newFiles.splice(newFiles.indexOf(file), 1)
    setFiles(newFiles)
  }

  const clearUpload = _ => {
    setUploadProgress(0)
    setFiles([])
  }

  const showSuccessMessage = message => {
    enqueueSnackbar(message, {
      variant: 'success',
      autoHideDuration: 5000
    })
  }

  const showErrorMessage = message => {
    enqueueSnackbar(message, {
      variant: 'error',
      autoHideDuration: 15000
    })
  }

  const handleClose = (reloadAfterClose = false) => onClose(reloadAfterClose);
  const handleUpload = _ => {
    const params = {
      ...queryScope,
      ruleVersion,
      logicVersion,
      deltaVersion
    };
    setUploadProgress(0)
    setUploading(true)

    const formData = new FormData();
    files.forEach(file => formData.append(`files[]`, file, file.name));

    axios({
      url: uploadUrl,
      method: 'post',
      headers: { "Content-Type": "multipart/form-data" },
      params: params,
      data: formData,
      onUploadProgress: progressEvent => {
        const { loaded, total } = progressEvent
        const progress = Math.floor((loaded * 100) / total)
        setUploadProgress(progress)
      }
    })
    .then(response => {
      const message = response.data.message;
      showSuccessMessage(message)
      handleClose(true)
    })
    .catch(error => {
      const response = error.response?.data;
      if (response) {
        const errors = response.errors ? response.errors : [response.error]
        errors.forEach(message => showErrorMessage(message))
      } else {
        showErrorMessage(error)
      }
    }).then(_ => {
      setUploading(false)
    });
  };

  const loadComponentVersions = _ => {
    const params = {...queryScope};
    setLoading(true)

    axios({
      url: componentVersionsUrl,
      timeout: 15000,
      method: 'get',
      headers: {'accept': 'application/json'},
      params: params
    })
    .then(response => {
      const logics = response.data?.data?.logic_versions || []
      const rules = response.data?.data?.rules_versions || []
      const deltas = response.data?.data?.delta_versions || []
      setLogicsVersions(logics)
      setLogicVersion(logics[0])
      setRulesVersions(rules)
      setRuleVersion(rules[0])
      setDeltasVersions(deltas)
      setDeltaVersion(deltas[0])
    })
    .catch(error => {
      const response = error.response;
      showErrorMessage(`${response.statusText}: ${response.data.error}`)
    }).then(_ => {
      setLoading(false)
    });
  }

  useEffect(_ => {
    loadComponentVersions();
  }, [])

  useEffect(_ => {
    let newFiles = [];
    acceptedFiles.forEach(newFile => {
      var index = files.findIndex(f => f.name==newFile.name);
      if (index === -1) newFiles.push(newFile)
    });
    setFiles([...files, ...newFiles])
  }, [acceptedFiles]);

  const LinearProgressBar = _ => {
    if (uploading && (uploadProgress == 0 || uploadProgress == 100)) {
      return <LinearProgress />
    }
    if (uploadProgress > 0) {
      return <LinearProgress variant="determinate" value={uploadProgress} />
    }
    return null;
  }

  return (
    <Drawer
      classes={{paper: classes.drawerPaper}}
      anchor='left'
      open={open}
      onClose={_ => handleClose(false)}
    >
      <Box
        display="flex"
        className={classes.toolbar}
        alignItems="center"
        justifyContent="space-between"
      >
        <Typography variant="h6">Nova Importação</Typography>
        <IconButton edge="end" color="inherit" onClick={_ => handleClose(false)}>
          <CloseIcon />
        </IconButton> 
      </Box>
      <main className={classes.content}>
        <Box mb={1} fontSize="body1.fontSize">
          Versão do componente de lógica
        </Box>
        {
          loading ?
          <Skeleton variant="text" height={50} /> :
          <AutoCompleteRemote
            onChange={newValue => setLogicVersion(newValue)}
            options={logicsVersions}
            defaultValue={logicVersion}
          />
        }
        <Box mt={2} mb={1} fontSize="body1.fontSize">
          Versão do componente de regras
        </Box>
        {
          loading ?
          <Skeleton variant="text" height={50} /> :
          <AutoCompleteRemote
            onChange={newValue => setRuleVersion(newValue)}
            options={rulesVersions}
            defaultValue={ruleVersion}
          />
        }
        <Box mt={2} mb={1} fontSize="body1.fontSize">
          Versão do Delta
        </Box>
        {
          loading ?
              <Skeleton variant="text" height={50} /> :
              <AutoCompleteRemote
                  onChange={newValue => setDeltaVersion(newValue)}
                  options={deltasVersions}
                  defaultValue={deltaVersion}
              />
        }
        {
          logicVersion && ruleVersion && !loading &&
          <>
            <Box mt={2} mb={1} fontSize="body1.fontSize">
              Formato suportado para importação: .xlsx - Planilha Excel 2007+.
            </Box>
            <div {...getRootProps({className: classes.dropzone})}>
              <input {...getInputProps()} />
              <Typography variant="h5">
                Arraste os arquivos para cá ou clique aqui para selecioná-los
              </Typography>
            </div>
            <List dense={true}>
              {files.map(file => (
                <ListItem key={file.name}>
                  <ListItemText
                    primary={file.name}
                    secondary={filesize(file.size)}
                  />
                  {
                    uploading == false &&
                    <ListItemSecondaryAction>
                      <IconButton edge="end" onClick={_ => handleRemoveFile(file)}>
                        <RemoveIcon size="small" />
                      </IconButton>
                    </ListItemSecondaryAction>
                    }
                </ListItem>
              ))}
            </List>
          </>
        }

        {
          files.length > 0 && logicVersion && ruleVersion &&  
          <>
            {uploading && <Box my={1}><LinearProgressBar /></Box>}
            <ButtonGroup fullWidth disabled={uploading}>
              <Button
                startIcon={<ClearAllIcon />}
                onClick={clearUpload}
              >
                Limpar Lista
              </Button>
              <Button color="primary"
                startIcon={<UploadIcon />}
                onClick={handleUpload}
              >
                {uploading ? 'Enviando...' : 'Enviar Arquivos'}
              </Button>
            </ButtonGroup>
          </>
        }
      </main>
    </Drawer>
  );
}

export default DrawerUpload;