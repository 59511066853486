import { Controller } from "@hotwired/stimulus"


// name => accounts--service-connections--new
export default class extends Controller {

    static targets = ['form']

    load_form() {
        this.formTarget.requestSubmit()
    }

}
