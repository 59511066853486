import { Controller } from "@hotwired/stimulus";
import { useDebounce } from "stimulus-use";

// Connects to data-controller="mosaic-bs--confirmation-form"
export default class extends Controller {
  static debounces = ["confirmationCheck"];
  static targets = ["form", "inputConfirmation", "btnSubmit"];
  static values = {confirmation: String};

  connect() {
    useDebounce(this, {wait: 300});
  }

  btnSubmitTargetConnected() {
    this.confirmationCheck(); // Avoid debounce
  }

  confirmationCheck() {
    const confirmTest = this.confirmationValue.toString().toLowerCase();
    const inputTest = this.inputConfirmationTarget.value.toString().toLowerCase();
    this.btnSubmitTarget.disabled = confirmTest !== inputTest;
  }
};