import React, { useState } from "react";
import PropTypes from "prop-types";
import axios from "axios";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import Box from "@material-ui/core/Box";
import AlertDialogSlide from "./AlertDialogSlide";

function ActionProgressButton(props) {
  const {
    btnSize,
    showAlertOnError,
    labelDefault,
    labelWorking,
    labelFailed,
    labelClose,
    labelRetry,
    urlAction,
  } = props;

  const [isWorking, setWorking] = useState(false);
  const [hasFailed, setFailed] = useState(false);
  const [showError, setShowError] = useState(false);
  const [contentError, setContentError] = useState("");

  function handleClick(e) {
    e.preventDefault();
    if (hasFailed) {
      setShowError(true);
    } else {
      callUrl(urlAction);
    }
  }

  function handleDialogCancel(e) {
    e.preventDefault();
    setShowError(false);
  }

  function handleDialogConfirm(e) {
    e.preventDefault();
    setShowError(false);
    setFailed(false);
    callUrl(urlAction);
  }

  function callUrl(url) {
    setWorking(true);
    return axios
      .get(url)
      .then((response) => {
        const eventCompleted = new CustomEvent("action-progress-button-completed", {
          detail: {message: response.data.notice},
        });
        document.dispatchEvent(eventCompleted);
      })
      .catch((error) => {
        setFailed(true);
        setContentError(error.response.data.error);
        if (showAlertOnError) {
          setShowError(true);
        }
      }).then(() => setWorking(false));
  }

  function ButtonLabel() {
    if (isWorking) return labelWorking;
    else if (hasFailed) return labelFailed;
    else return labelDefault;
  }

  return (
    <>
      <AlertDialogSlide
        title={labelFailed}
        contentText={contentError}
        isOpen={showError}
        onClose={handleDialogCancel}
        onConfirm={handleDialogConfirm}
        labelClose={labelClose}
        labelConfirm={labelRetry}
      />
      <Button
        variant="outlined"
        color={hasFailed ? "secondary" : "default"}
        disabled={isWorking}
        onClick={handleClick}
        size={btnSize}
        disableElevation
      >
        {isWorking && (
          <Box component="span" mr={1}>
            <CircularProgress size={14} />
          </Box>
        )}
        {ButtonLabel()}
      </Button>
    </>
  );
}

ActionProgressButton.propTypes = {
  btnSize: PropTypes.string,
  showAlertOnError: PropTypes.bool,
  labelDefault: PropTypes.string,
  labelWorking: PropTypes.string,
  labelFailed: PropTypes.string,
  labelClose: PropTypes.string,
  labelRetry: PropTypes.string,
  urlAction: PropTypes.string.isRequired
};

ActionProgressButton.defaultProps = {
  btnSize: "small",
  showAlertOnError: false,
  labelDefault: "Executar",
  labelWorking: "Executando...",
  labelFailed: "Falhou!",
  labelClose: "Close",
  labelRetry: "Retry"
};

export default ActionProgressButton;
