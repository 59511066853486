import {Controller} from "@hotwired/stimulus"
import moment from 'moment';

// Connects to data-controller="elapsed-time"
export default class extends Controller {
  static values = {
    startedAt: {type: String, default: ''},
    finishedAt: {type: String, default: ''},
    disabled: {type: Boolean, default: false},
    refreshInterval: {type: Number, default: 1000}
  }

  connect() {
    this.tick()

    if (this.hasRefreshIntervalValue && this.disabledValue === false) {
      this.startRefreshing()
    }
  }

  disconnect() {
    this.stopRefreshing()
  }

  tick() {
    this.element.innerHTML = this.outputTime()
  }

  startRefreshing() {
    this.refreshTimer = setInterval(() => {
      this.tick()
    }, this.refreshIntervalValue)
  }

  stopRefreshing() {
    if (this.refreshTimer) {
      clearInterval(this.refreshTimer)
    }
  }

  outputTime() {
    const started = this.startedAtValue === '' ? moment() : moment(this.startedAtValue)
    const finished = this.finishedAtValue === '' ? moment() : moment(this.finishedAtValue)
    const diff = finished.diff(started)
    const duration = moment.duration(diff).asMilliseconds()
    return moment.utc(duration).format('HH:mm:ss');
  }
}
