import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import axios from "axios";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import Box from "@material-ui/core/Box";
import AlertDialogSlide from "./AlertDialogSlide";
import { useBeforeunload } from 'react-beforeunload';
import { clearForm } from "./utils";

function SubmitProgressButton(props) {
  const {
    btnSize,
    labelDefault,
    labelWorking,
    labelFailed,
    labelFinished,
    labelClose,
    labelReset,
    formId,
  } = props;

  const [isWorking, setWorking] = useState(false);
  const [hasFailed, setFailed] = useState(false);
  const [hasFinish, setFinish] = useState(false);
  const [showDialog, setShowDialog] = useState(false);
  const [showFullScreen, setShowFullScreen] = useState(false);
  const [dialogContent, setDialogContent] = useState("");
  const [dialogTitle, setDialogTitle] = useState("");
  const [buttonColor, setButtonColor] = useState("default");
  const formEl = document.getElementById(formId);

  function handleClick(e) {
    e.preventDefault();
    if (hasFailed || hasFinish) {
      setShowDialog(true);
    } else {
      var evt = document.createEvent("Event");
      evt.initEvent("submit", true, true);
      formEl.dispatchEvent(evt);
    }
  }

  function handleDialogClose(e) {
    e.preventDefault();
    setShowDialog(false);
  }

  function handleDialogConfirm(e) {
    e.preventDefault();
    setShowDialog(false);
    setFailed(false);
    setFinish(false);
    setDialogContent("");
    setButtonColor("default");
    setShowFullScreen(false);
    clearForm(formEl);
  }

  function fail(content) {
    setFailed(true);
    setDialogTitle(labelFailed);
    setDialogContent(content);
    setButtonColor("secondary");
    setShowFullScreen(false);
  }

  function finish(content) {
    setFinish(true);
    setDialogTitle(labelFinished);
    setDialogContent(content);
    setButtonColor("primary");
    setShowFullScreen(true);
  }

  function callForm() {
    setWorking(true);
    const csrf = document.querySelector("meta[name='csrf-token']").getAttribute("content");
    return axios({
        method: formEl.method,
        url: formEl.action,
        data: new FormData(formEl),
        headers: {
          "Content-Type": "multipart/form-data",
          'X-CSRF-Token': csrf
        },
      })
      .then(response => {
        const eventCompleted = new CustomEvent("submit-progress-button-completed", {
          detail: {message: response.data},
        });
        document.dispatchEvent(eventCompleted);
        finish(response.data)
      })
      .catch(error => {
        fail(error.response.data || error.message);
      }).then(() => setWorking(false));
  }

  function ButtonLabel() {
    if (isWorking) return labelWorking;
    else if (hasFailed) return labelFailed;
    else if (hasFinish) return labelFinished;
    else return labelDefault;
  }

  useEffect(() => {
    formEl.addEventListener("submit", function(event) {
      event.preventDefault();
      callForm()
    });
  }, []);

  useBeforeunload(event => {
    if (isWorking) {
      event.preventDefault();
    }
  });

  return (
    <>
      <AlertDialogSlide
        title={dialogTitle}
        contentText={dialogContent}
        isOpen={showDialog}
        showFullScreen={showFullScreen}
        onClose={handleDialogClose}
        onConfirm={handleDialogConfirm}
        labelClose={labelClose}
        labelConfirm={labelReset}
      />
      <Button
        variant="outlined"
        color={buttonColor}
        disabled={isWorking}
        onClick={handleClick}
        size={btnSize}
        disableElevation
      >
        {isWorking && (
          <Box component="span" mr={1}>
            <CircularProgress size={14} />
          </Box>
        )}
        {ButtonLabel()}
      </Button>
    </>
  );
}

SubmitProgressButton.propTypes = {
  btnSize: PropTypes.string,
  labelDefault: PropTypes.string,
  labelWorking: PropTypes.string,
  labelFailed: PropTypes.string,
  labelFinished: PropTypes.string,
  labelClose: PropTypes.string,
  labelReset: PropTypes.string,
  formId: PropTypes.string.isRequired
};

SubmitProgressButton.defaultProps = {
  btnSize: "small",
  labelDefault: "Executar",
  labelWorking: "Executando...",
  labelFailed: "Falhou!",
  labelFinished: "Finalizou!",
  labelClose: "Close",
  labelReset: "Retry"
};

export default SubmitProgressButton;
