import { Controller } from "@hotwired/stimulus"


// Connects to data-controller="audit-trail--form"
export default class extends Controller {

  connect() {
    const that = this
    this.element.addEventListener('turbo:submit-end', (event) => {
      if (event.detail.success) {
        that.dispatch("submitted")
      }
    })
  }
}
