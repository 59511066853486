import { Controller } from "@hotwired/stimulus"
import { updateIdsOnTargetUrl } from "../../utils/url"
import { toggleDisabled } from "../../utils/visibility";

// Connects to data-controller="scenarios--calc-test-histories--table"
export default class extends Controller {
  static targets = ["btnCompare"];

  connect() {}

  onChangeCheckbox(ev) {
    const values = ev.detail.pageValues
    updateIdsOnTargetUrl(this.btnCompareTarget, values)
    const checkedCount = values.length
    toggleDisabled(this.btnCompareTarget, checkedCount < 2 || checkedCount > 3)
  }
}
