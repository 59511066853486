import React, { useState, useEffect } from "react";
import { makeStyles, withStyles } from '@material-ui/core/styles';
import PropTypes from "prop-types";

import Button from "@material-ui/core/Button";
import LinearProgress from '@material-ui/core/LinearProgress';

const TransparentLinearProgress = withStyles((theme) => ({
  root: {
    height: 1
  },
  colorPrimary: {
    backgroundColor: 'transparent'
  },
  bar: {
    borderRadius: 0,
    backgroundColor: theme.palette.grey[700],
  },
}))(LinearProgress);


const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  control: {
    padding: theme.spacing(0, 0, 2),
  },
  bottomProgress: {
    position: 'absolute',
    bottom: 0,
    right: 0,
    left: 0,
  }
}));

function ButtonAutoReload(props) {
  const { loading, seconds, onClick } = props;

  const [ timeLeft, setTimeLeft ] = useState(seconds);
  const [ progress, setProgress ] = useState(100);
  const classes = useStyles();

  const handleClick = e => {
    e.preventDefault();
    onClick(e)
    setTimeLeft(seconds)
  }

  useEffect(e => {
    if (timeLeft===0) {
      onClick(e)
      setTimeLeft(seconds)
    }

    if (!timeLeft) return;

    const intervalId = setInterval(_ => {
      const newTimeLeft = timeLeft - 1
      setProgress(Math.round(newTimeLeft / seconds * 100));
      setTimeLeft(newTimeLeft);
    }, 500);

    return _ => clearInterval(intervalId);
  }, [timeLeft]);

  useEffect(e => {
    setTimeLeft(seconds)
  }, [loading]);

  return (
    <>
      <Button
        variant="outlined"
        disableElevation 
        {...props}
        disabled={loading}
        onClick={handleClick}
      >
        { props.children }
        {!loading && <TransparentLinearProgress className={classes.bottomProgress} variant="determinate" value={progress} />}
      </Button>
    </>
  );
}

ButtonAutoReload.propTypes = {
  seconds: PropTypes.number.isRequired
};

ButtonAutoReload.defaultProps = {
  seconds: 60
};


export default ButtonAutoReload;
