import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import axios from "axios";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import Box from "@material-ui/core/Box";
import AlertDialogSlide from "./AlertDialogSlide";
import { usePrevious } from "./utils";

function JobProgressButton(props) {
  const {
    btnSize,
    labelDefault,
    labelWorking,
    labelFailed,
    labelClose,
    labelRetry,
    statusWorking,
    statusFailed,
    statusOk,
    urlStatus,
    urlStart,
    urlRetry,
    checkInterval,
  } = props;

  const [isWorking, setWorking] = useState(false);
  const [hasFailed, setFailed] = useState(false);
  const [status, setStatus] = useState(null);
  const [showError, setShowError] = useState(false);
  const [contentError, setContentError] = useState("");
  const prevStatus = usePrevious(status);
  const eventCompleted = new CustomEvent("job-progress-button-completed", {
    detail: { status: status },
  });

  function handleClick(e) {
    e.preventDefault();
    if (hasFailed) {
      setShowError(true);
    } else {
      callUrl(urlStart);
    }
  }

  function handleDialogCancel(e) {
    e.preventDefault();
    setShowError(false);
  }

  function handleDialogConfirm(e) {
    e.preventDefault();
    setShowError(false);
    callUrl(urlRetry);
  }

  function callUrl(url) {
    setWorking(true);
    return axios
      .get(url)
      .then((response) => {
        // console.log(response);
        const dataStatus = response.data.status;
        if (statusWorking.includes(dataStatus)) {
          // console.log("Agendando para daqui " + checkInterval);
          setTimeout(() => getStatus(), checkInterval * 1000);
        }
        setStatus(dataStatus);
      })
      .catch((error) => {
        // console.log("error", error);
        setStatus(error.response.data.status);
        setContentError(error.response.data.error);
      });
  }

  function getStatus() {
    callUrl(urlStatus);
  }

  useEffect(() => {
    setWorking(statusWorking.includes(status));
    setFailed(statusFailed.includes(status));
    if (status === null) getStatus();
    if (
      (statusWorking.includes(prevStatus) ||
        statusFailed.includes(prevStatus)) &&
      statusOk.includes(status)
    ) {
      document.dispatchEvent(eventCompleted);
    }
  }, [status]);

  function ButtonLabel() {
    if (status == null) return "...";
    else if (isWorking) return labelWorking;
    else if (hasFailed) return labelFailed;
    else return labelDefault;
  }

  return (
    <>
      <AlertDialogSlide
        title={labelFailed}
        contentText={contentError}
        isOpen={showError}
        onClose={handleDialogCancel}
        onConfirm={handleDialogConfirm}
        labelClose={labelClose}
        labelConfirm={labelRetry}
      />
      <Button
        variant="outlined"
        color={hasFailed ? "secondary" : "default"}
        disabled={isWorking}
        onClick={handleClick}
        size={btnSize}
        disableElevation
      >
        {isWorking && (
          <Box component="span" mr={1}>
            <CircularProgress size={14} />
          </Box>
        )}
        {ButtonLabel()}
      </Button>
    </>
  );
}

JobProgressButton.propTypes = {
  btnSize: PropTypes.string,
  labelDefault: PropTypes.string,
  labelWorking: PropTypes.string,
  labelFailed: PropTypes.string,
  labelClose: PropTypes.string,
  labelRetry: PropTypes.string,
  statusWorking: PropTypes.array,
  statusFailed: PropTypes.array,
  statusOk: PropTypes.array,
  checkInterval: PropTypes.number,
  urlStatus: PropTypes.string.isRequired,
  urlStart: PropTypes.string.isRequired,
  urlRetry: PropTypes.string,
};

JobProgressButton.defaultProps = {
  btnSize: "small",
  labelDefault: "Executar",
  labelWorking: "Executando...",
  labelFailed: "Falhou!",
  statusWorking: ["working"],
  statusFailed: ["failed"],
  statusOk: ["ok"],
  labelClose: "Close",
  labelRetry: "Retry",
  checkInterval: 5,
};

export default JobProgressButton;
