import { Controller } from "@hotwired/stimulus";
import { useTransition, useMemo } from "stimulus-use";

export default class Dropdown extends Controller {
  static targets = ["menu"];
  static memos = ["isItToAlignToTheRight", "isItInsideATableResponsive"];

  connect() {
    useMemo(this);

    useTransition(this, {
      element: this.menuTarget,
      hiddenClass: false
    });
  }

  toggle() {
    const willItBeToggledToVisible = this.isMenuHidden;
    this.toggleTransition();
    this.menuTarget.classList.toggle("show");
    if (willItBeToggledToVisible && this.isWithinTableResponsive) {
      this._alignPosition(this.isItToAlignToTheRight ? "right" : "left");
    }
  }

  hide(event) {
    //Close other dropdowns, triggered by click@window->dropdown#hide
    if (!this.element.contains(event.target) && this.isMenuVisible) {
      this.menuTarget.classList.remove("show");
      this.leave();
    }
  }

  _alignPosition(direction = "left") {
    const dropdownMenu = this.menuTarget;
    const dropdownButton = dropdownMenu.previousElementSibling;
    const buttonRect = dropdownButton.getBoundingClientRect();
    const scrollY = window.pageYOffset || document.documentElement.scrollTop;
    const topPosition = buttonRect.bottom + scrollY;
    dropdownMenu.style.zIndex = "1000";
    dropdownMenu.style.position = "absolute";

    if (direction === "right") {
      dropdownMenu.style.top = `${topPosition}px`;
      dropdownMenu.style.left = `${buttonRect.left + buttonRect.width - dropdownMenu.offsetWidth}px`;
    } else {
      dropdownMenu.style.top = `${topPosition}px`;
      dropdownMenu.style.left = `${buttonRect.left}px`;
    }
  }

  get isMenuVisible() {
    return this.menuTarget.classList.contains("show");
  }

  get isMenuHidden() {
    return this.isMenuVisible === false;
  }

  get isItToAlignToTheRight() {
    return this.menuTarget.className.split(" ").some(c => /dropdown-menu(-[^-]+)?-end/.test(c));
  }

  get isWithinTableResponsive() {
    return this.menuTarget.closest(".table-responsive") !== null;
  }
}