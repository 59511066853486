import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="gtin-validations--table-actions"
export default class extends Controller {
  static values = {
    waiting: Boolean,
    url: String
  }

  connect() {
    if (this.waitingValue) {
      window.setTimeout(_ => this.refresh(), 5000)
    }
  }

  refresh() {
    fetch(this.urlValue)
    .then (response => response.text())
    .then(html => Turbo.renderStreamMessage(html));
  }

}
