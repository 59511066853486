import { Controller } from "@hotwired/stimulus";
import { delegate, abnegate } from "jquery-events-to-dom-events";
import { updateUrlParam } from "./utils/url";

// Connects to data-controller="pagination-per-page"
export default class extends Controller {
  static targets = ["link", "select"];
  static values = {paramName: String};

  connect() {
    if (this.hasSelectTarget) {
      this.selectTarget.delegate = delegate("change");
      this.selectTarget.addEventListener("change", ev => this.handlerChange(ev));
      this.selectTarget.addEventListener("$change", ev => this.handlerChange(ev));
    }
  }

  disconnect() {
    if (this.hasSelectTarget) {
      abnegate("change", this.selectTarget.delegate);
      this.selectTarget.removeEventListener("change", ev => this.handlerChange(ev));
      this.selectTarget.removeEventListener("$change", ev => this.handlerChange(ev));
    }
  }

  handlerChange(ev) {
    ev.preventDefault();
    if (this.hasLinkTarget) {
      this.linkTarget.setAttribute("href", updateUrlParam(this.linkTarget.href, this.paramNameValue, this.selectedValue));
      this.linkTarget.click();
    }
  }

  get selectedValue() {
    if (this.hasSelectTarget) {
      return this.selectTarget.value;
    }
    return 15;
  }
}
