import { Controller } from "@hotwired/stimulus";
import { easepick, RangePlugin, PresetPlugin, DateTime } from "@easepick/bundle";
import { customLabels as PresetPluginCustomLabels } from "../plugins/easepick/preset_plugin";
import { locale as RangePluginLocale } from "../plugins/easepick/range_plugin";
import { locale as CorePluginLocale } from "../plugins/easepick/core_plugin";
import { styles as BundleCss } from "../plugins/easepick/styles";
import { replyEvent } from "./utils/events";

// Connects to data-controller="ease-pick"
export default class extends Controller {
  static values = {
    date: {type: String, default: ""},
    format: {type: String, default: "YYYY-MM-DD"},
    wrapper: {type: String, default: ""},
    grid: {type: Number, default: 1},
    calendars: {type: Number, default: 1},
    autoApply: {type: Boolean, default: true},
    inline: {type: Boolean, default: false},
    rangeDelimiter: {type: String, default: "..."},
    rangeDate: {type: String, default: ""},
    preset: {type: Boolean, default: true},
    presetPosition: {type: String, default: "left"}
  };

  initialize() {
    this.currentLocale = document.documentElement.lang;
    this.picker = null;
    super.initialize();
  }

  connect() {
    const that = this;
    this.normalizeDateFields();
    this.picker = new easepick.create({
      element: this.element,
      zIndex: 1050, /* 1060 = Backdrop */
      date: this.dateValue,
      css: BundleCss,
      grid: this.gridValue,
      calendars: this.calendarsValue,
      inline: this.inlineValue,
      autoApply: this.autoApplyValue,
      lang: this.currentLocale,
      locale: CorePluginLocale[this.currentLocale],
      format: this.formatValue,
      plugins: this.activePlugins,
      RangePlugin: {
        tooltip: true,
        delimiter: this.rangeDelimiterValue,
        startDate: this.startDateValue,
        endDate: this.endDateValue,
        locale: RangePluginLocale[this.currentLocale]
      },
      PresetPlugin: {
        position: this.presetPositionValue,
        customLabels: PresetPluginCustomLabels[this.currentLocale]
      },
      setup(picker) {
        if (that.wrapperValue !== "") that._moveToWrapper(picker);

        picker.on("render", (e) => replyEvent(e, that.element));
        picker.on("preselect", (e) => replyEvent(e, that.element));
        picker.on("select", (e) => that.changeEvent(e, that.element));
        picker.on("clear", (e) => that.changeEvent(e, that.element));
      }
    });

    if (this.calendarsValue > this.gridValue) {
      this.picker.ui.container.classList.add("grid-2plus");
    }

    this.element.addEventListener("set-date", (e) => {
      if (e.detail.match(new RegExp(this.rangeDelimiterValue))) {
        const [startDate, endDate] = e.detail.split(this.rangeDelimiterValue);
        that.picker.setStartDate(startDate);
        that.picker.setEndDate(endDate);
      } else {
        that.picker.setDate(e.detail);
      }
      that.triggerSelected();
    });
  }

  disconnect() {
    this.picker.destroy();
  }

  triggerSelected() {
    this.picker.trigger("select", {
      start: this.picker.getStartDate(),
      end: this.picker.getEndDate()
    });
  }

  changeEvent(originalEvent, targetElement) {
    replyEvent(originalEvent, targetElement); // Original EventName
    replyEvent(originalEvent, targetElement, "change");
  }

  _moveToWrapper(picker) {
    const newWrapper = document.querySelector(this.wrapperValue);
    if (newWrapper) newWrapper.appendChild(picker.ui.wrapper);
  }

  normalizeDateFields() {
    if (this.dateValue === "") {
      this.dateValue = DateTime.now();
      return;
    }

    try {
      if (this.dateValue.match(new RegExp(this.rangeDelimiterValue))) {
        const [startDate, endDate] = this.dateValue.split(this.rangeDelimiterValue);
        this.startDateValue = startDate;
        this.endDateValue = endDate;
      }
    } catch (error) {
      console.error("Invalid date format. Using current date instead.");
      this.dateValue = DateTime.now();
    }
  }

  get activePlugins() {
    let plugins = [RangePlugin];
    if (this.presetValue) plugins.push(PresetPlugin);

    return plugins;
  }
}
