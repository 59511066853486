import React from 'react';
import moment from 'moment'

function DateTimeField({value, format='L LT'}) {
  const dateTime = value ? moment(value).format(format) : '...'
  return <>
    {dateTime}
  </>
}

export default DateTimeField;