import { Controller } from "@hotwired/stimulus";
import { updateUrlParam, removeUrlParam } from "../utils/url";
import { toggleDisabled } from "../utils/visibility";

// Connects to data-controller="tax-rules--table"
export default class extends Controller {
  static targets = ["btnDestroy"];

  connect() {
  }

  onCheckboxChange({detail: {pageValues, searchAllChecked, searchParamValues}}) {
    const noSearchAll = searchAllChecked === undefined || searchAllChecked === false;
    this._disableButtons(pageValues.length < 1 && noSearchAll);
    this.selectedIds = pageValues;

    if (searchAllChecked) {
      this._removeUrlParam("ids");
      this._updateUrlParam("search", searchParamValues);
    } else {
      this._removeUrlParam("search");
      this._updateUrlParam("ids", pageValues);
    }
  }

  _updateUrlParam(paramName, values) {
    this.btnDestroyTarget.href = updateUrlParam(this.btnDestroyTarget.href, paramName, values);
  }

  _removeUrlParam(paramName) {
    removeUrlParam(this.btnDestroyTarget.href, paramName);
  }

  _disableButtons(disabled = false) {
    toggleDisabled(this.btnDestroyTarget, disabled);
  }

}
