import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="dispatch-event"
export default class extends Controller {
  send({params}) {
    const eventName = params.name || "sent";
    const detail = params.detail || {};
    const targetElement = document.querySelector(params.target) || this.element;
    const event = new CustomEvent(eventName, {
      bubbles: true,
      cancelable: true,
      detail: detail
    });
    targetElement.dispatchEvent(event);
  }
}
