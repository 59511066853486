class TurboForm {
  constructor(target) {
    this.form = document.createElement("form");
    this.target = target;
    this.form.method = target.getAttribute("data-turbo-method") || target.getAttribute("data-method") || target.getAttribute("method") || "GET";
    this.form.action = target.getAttribute("href") || target.getAttribute("action") || "#";
    this.form.dataset.turbo = target.getAttribute("data-turbo") || "true";

    const confirmMessage = target.getAttribute("data-turbo-confirm") || target.getAttribute("data-confirm") || "";
    if (confirmMessage !== "") this.form.dataset.turboConfirm = confirmMessage;

    const frame = target.getAttribute("data-turbo-frame") || target.getAttribute("target") || "";
    if (frame !== "") this.form.dataset.turboFrame = frame;

    const submitsWith = target.getAttribute("data-turbo-submits-with") || "";
    if (submitsWith !== "") this.form.dataset.turboSubmitsWith = submitsWith;
  }

  addField(name, value, type = "hidden") {
    const input = document.createElement("input");
    input.type = type;
    input.name = name;
    input.value = value;
    this.form.appendChild(input);
  }

  addFields(fields) {
    fields.forEach(field => this.addField(field.name, field.value, field.type));
  }

  submit() {
    const formClone = this.form.cloneNode(true);
    document.body.appendChild(formClone);
    formClone.requestSubmit();
    formClone.remove();
  }
}

export default TurboForm;