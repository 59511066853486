import React, { useState, useEffect } from 'react';
import PropTypes from "prop-types";
import axios from "axios";
import { useSnackbar } from 'notistack';
import {
  useLocation,
  useHistory
} from "react-router-dom";

import { makeStyles, withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import VisibilityIcon from '@material-ui/icons/Visibility';
import AddIcon from '@material-ui/icons/Add';
import ReloadIcon from '@material-ui/icons/Cached';
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import LinearProgress from '@material-ui/core/LinearProgress';
import TablePagination from '@material-ui/core/TablePagination';
import Box from '@material-ui/core/Box';

import TableItemMenu from './TableItemMenu';
import DrawerDataView from './DrawerDataView';
import DrawerUpload from './DrawerUpload';
import RecordStatus from './RecordStatus';
import DateTimeField from './DateTimeField';
import ButtonAutoReload from './ButtonAutoReload';

const TableCellHead = withStyles(theme => ({
  root: {
    fontWeight: theme.typography.h6.fontWeight,
    fontSize: theme.typography.h6.fontSize
  },
}))(TableCell);

const useStylesTableGrid = makeStyles(theme => ({
  paperContainer: {
    marginTop: 10,
  },
  buttons: {
    marginBottom: 20,
  },
  tableContainer: {
    position: 'relative',
    minHeight: 80,
    overflowX: 'visible'
  },
  tableLoadingCell: {
    padding: 0,
  },
  tableLoadingProgress: {
    position: 'absolute',
    width: '100%'
  },
  table: {
    minWidth: 650,
  },
  tableRowEmpty: {
    textAlign: 'center'
  },
  tableCellNowrap: {
    whiteSpace: 'nowrap'
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
}));

function TableGrid(props) {
  const {
    queryScope,
    uploadUrl,
    searchUrl,
    componentVersionsUrl,
    downloadSpreadsheetModelUrl,
    perPageRange,
    perPage,
    page
  } = props

  const { enqueueSnackbar, _closeSnackbar } = useSnackbar();
  const history = useHistory();
  const locationSearch = useLocation().search;
  const classes = useStylesTableGrid();
  const [loading, setLoading] = useState(false);
  const [downloading, setDownloading] = useState(false);
  const [deleting, setDeleting] = useState(false);
  const [rows, setRows] = useState([]);
  const [totalRows, setTotalRows] = useState(-1);
  const [currentPage, setCurrentPage] = useState(page);
  const [currentPerPage, setCurrentPerPage] = useState(perPage || perPageRange[0]);
  const [recordLinks, setRecordLinks] = useState();
  const [showNewRecord, setShowNewRecord] = useState(false);
  const [order, setOrder] = useState('desc');
  const [orderBy, setOrderBy] = useState('created_at');

  const handleShowRecord = links => setRecordLinks(links)
  const handleHideRecord = _ => setRecordLinks()
  const handleReloadClick = _ => doSearch()
  const handlePageChange = (_, newPage) => setQueryParam({page: (newPage + 1)})
  const handlePerPageChange = ev => setQueryParam({perPage: +ev.target.value})
  const handleDownloadSpreadsheetModelClick = _ => doDownload(downloadSpreadsheetModelUrl)
  const handleNewRecordClose = reloadAfterClose => {
    // console.log('reloadAfterClose', reloadAfterClose)
    setShowNewRecord(false)
    if (reloadAfterClose) {
      currentPage == 1 ? doSearch() : setCurrentPage(1)
    }
  }
  const handleRequestSort = (_, field) => {
    const isAsc = orderBy === field && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(field);
  };

  const showSuccessMessage = message => {
    enqueueSnackbar(message, {
      variant: 'success',
      autoHideDuration: 5000
    })
  }

  const showErrorMessage = message => {
    enqueueSnackbar(message, {
      variant: 'error',
      autoHideDuration: 15000
    })
  }

  const TableProgress = _ => (
    <TableRow>
      <TableCell className={classes.tableLoadingCell}>
        <LinearProgress className={classes.tableLoadingProgress} />
      </TableCell>
    </TableRow>
  )

  const TableRowEmpty = _ => {
    return (
      <TableRow hover tabIndex={-1}>
        <TableCell colSpan={8}>
          <Box
            textAlign="center"
            fontSize="h6.fontSize"
            py={2}
          >
            Nenhum registro encontrado
          </Box>
        </TableCell>
      </TableRow>
    )
  }

  const TableSortHead = props => {
    const { cell } = props;
    const field = cell.id
    const elementId = `table-head-${field}`
    
    if (cell.disableOrder) {
      return <TableCellHead data-field={field} id={elementId}>{props.children}</TableCellHead>
    }
    return (
      <TableCellHead
        id={elementId}
        data-field={field}
        sortDirection={orderBy === field ? order : false}
      >
        <TableSortLabel
          active={orderBy === field}
          direction={orderBy === field ? order : 'asc'}
          onClick={event => handleRequestSort(event, field)}
        >
          {props.children}
        </TableSortLabel>
      </TableCellHead>
    )
  }

  const headCells = [
    { id: 'filename', label: 'Nome do Arquivo' },
    { id: 'created_at', label: 'Enviado em' },
    { id: 'user', label: 'Enviado por', disableOrder: true },
    { id: 'rules_version', label: 'Regras' },
    { id: 'logic_version', label: 'Lógica' },
    { id: 'delta_version', label: 'Delta' },
    { id: 'status', label: 'Status' },
  ];

  const doDownload = apiUrl => {
    setDownloading(true)
    return axios({
        url: apiUrl,
        method: 'get',
        headers: {'accept': 'application/json'},

      })
      .then(response => {
        const {url, filename} = response.data;
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download',filename);
        document.body.appendChild(link);
        link.click();
        link.parentNode.removeChild(link);
      })
      .catch(error => {
        const response = error.response;
        showErrorMessage(`${response.statusText}: ${response.data.error}`)
      }).then(_ => {
        setDownloading(false)
      });
  }

  const doDelete = apiUrl => {
    const params = {
      ...queryScope
    };

    setDeleting(true)
    return axios({
        url: apiUrl,
        timeout: 15000,
        method: 'delete',
        headers: {'accept': 'application/json'},
        params: params,
      })
      .then(response => {
        const message = response.data.data;
        showSuccessMessage(message);
      })
      .catch(error => {
        const response = error.response;
        showErrorMessage(`${response.statusText}: ${response.data.error}`)
      }).then(_ => {
        setDeleting(false)
        doSearch();
      });
  }

  const doSearch = _ => {
    if (currentPage==0 || currentPerPage==0) return;

    const paginationParams = {page: currentPage, perPage: currentPerPage}
    const orderParams = {order, orderBy}
    const params = {
      ...orderParams,
      ...paginationParams,
      ...queryScope
    };

    setLoading(true);
    return axios({
        url: searchUrl,
        timeout: 15000,
        method: 'get',
        headers: {'accept': 'application/json'},
        params: params
      })
      .then(response => {
        setRows(response.data.data);
        setTotalRows(response.data.metadata.count);
      })
      .catch(error => {
        const response = error.response;
        const className = response.data.metadata.class;
        if (className == 'Pagy::OverflowError') {
          setQueryParam({page: 1})
          return
        }
        showErrorMessage(`${response.statusText}: ${response.data.error}`)
        setRows([]);
        setTotalRows(0);
      }).then(_ => {
        setLoading(false)
      });
  }

  const getParamsFromUrl = _ => {
    const urlParams = new URLSearchParams(locationSearch);
    let newPage = +urlParams.get("page");
    if (newPage <= 0) newPage = 1
    let newPerPage = +urlParams.get("perPage");
    if (newPerPage <= 0) newPerPage = perPageRange[0]
    setCurrentPage(newPage)
    setCurrentPerPage(newPerPage)
  }

  const setQueryParam = (params) => {
    let queryParams = new URLSearchParams(locationSearch);
    Object.keys(params).map(k => queryParams.set(k, params[k]))
    history.push(`/?${queryParams.toString()}`)
  }

  useEffect(_ => {
    getParamsFromUrl()
  }, [locationSearch]);

  useEffect(_ => {
    doSearch()
  }, [order, orderBy]);

  useEffect(_ => {
    doSearch()
  }, [currentPage, currentPerPage]);

  useEffect(_ => {
    getParamsFromUrl()
  }, []);

  return (
    <>
      {showNewRecord && <DrawerUpload
        uploadUrl={uploadUrl}
        componentVersionsUrl={componentVersionsUrl}
        queryScope={queryScope}
        open={showNewRecord}
        onClose={handleNewRecordClose}
      />}
      {recordLinks && <DrawerDataView links={recordLinks} onClose={handleHideRecord} />}
      <Paper elevation={0} className={classes.paperContainer}>
        {/* <ActionMenu /> */}
        <ButtonGroup size="small" disableElevation className={classes.buttons}>
          <Button
            id="btn-new-record"
            color="primary"
            startIcon={<AddIcon />}
            onClick={() => setShowNewRecord(true)}
          >
            Nova Importação
          </Button>
          <Button
            id="btn-download-spreadsheet-model"
            disabled={downloading}
            startIcon={<CloudDownloadIcon />}
            onClick={handleDownloadSpreadsheetModelClick}
          >
            Baixar Planilha Modelo
          </Button>
          <ButtonAutoReload
            id="btn-reload-records"
            loading={loading}
            startIcon={<ReloadIcon />}
            onClick={handleReloadClick}
          >
            Recarregar
          </ButtonAutoReload>
        </ButtonGroup>
        <TableContainer className={classes.tableContainer}>
          <Table className={classes.table} size="small">
            <TableHead>
              <TableRow className={classes.tableRow}>
                {headCells.map(headCell => (
                  <TableSortHead key={headCell.id} cell={headCell}>
                    {headCell.label}
                    {orderBy === headCell.id ? (
                      <span className={classes.visuallyHidden}>
                        {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                      </span>
                    ) : null}
                  </TableSortHead>
                ))}
                <TableCell align="right" />
              </TableRow>
              { loading && <TableProgress className={classes.tableHeadLoading} />}
            </TableHead>
            <TableBody>
              { totalRows === 0 && <TableRowEmpty /> }
              {
                rows.map(row => (
                  <TableRow
                    id={`table-row-${row.protocol}`}
                    data-row-id={row.protocol}
                    hover
                    key={row.protocol}
                    tabIndex={-1}>
                    <TableCell data-id={`table-row-${row.protocol}-filename`}>{row.filename}</TableCell>
                    <TableCell data-id={`table-row-${row.protocol}-created_at`}><DateTimeField value={row.created_at} /></TableCell>
                    <TableCell data-id={`table-row-${row.protocol}-user_name`}>{row.user.name}</TableCell>
                    <TableCell data-id={`table-row-${row.protocol}-rules_version`}>{row.rules_version}</TableCell>
                    <TableCell data-id={`table-row-${row.protocol}-logic_version`}>{row.logic_version}</TableCell>
                    <TableCell data-id={`table-row-${row.protocol}-delta_version`}>{row.delta_version}</TableCell>
                    <TableCell data-id={`table-row-${row.protocol}-status`}>
                      <RecordStatus
                        status={row.status}
                        error={row.error}
                        time={row.total_time}
                      />
                    </TableCell>
                    <TableCell align="right" className={classes.tableCellNowrap}>
                      <IconButton
                        id={`btn-show-${row.protocol}`}
                        onClick={_ => handleShowRecord(row.links)}
                        size="small"
                      >
                        <VisibilityIcon />
                      </IconButton>
                      <TableItemMenu
                        row={row}
                        onDelete={apiUrl => doDelete(apiUrl)}
                        onDownload={apiUrl => doDownload(apiUrl)}
                      />
                    </TableCell>
                  </TableRow>
                ))
              }
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
      <Grid
        container
        direction="row"
        justifyContent="flex-end"
        alignItems="center"
      >
        <TablePagination
          id="table-pagination"
          labelRowsPerPage="por página:"
          labelDisplayedRows={({ from, to, count }) => (count !== -1 ? `${from}-${to} de ${count}` : `mais de ${to}`)}
          component="div"
          count={totalRows}
          page={currentPage - 1}
          rowsPerPage={currentPerPage}
          rowsPerPageOptions={perPageRange}
          onRowsPerPageChange={handlePerPageChange}
          onPageChange={handlePageChange}
        />
      </Grid>
    </>
  )
}

TableGrid.propTypes = {
  uploadUrl: PropTypes.string.isRequired,
  searchUrl: PropTypes.string.isRequired,
  componentVersionsUrl: PropTypes.string.isRequired,
  queryScope: PropTypes.object.isRequired,
  downloadSpreadsheetModelUrl: PropTypes.string.isRequired,
  perPageRange: PropTypes.array,
  perPage: PropTypes.number,
  page: PropTypes.number
};

TableGrid.defaultProps = {
  perPageRange: [10, 25, 50],
  perPage: 0,
  page: 0
};

export default TableGrid;