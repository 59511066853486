import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="flash-message"
export default class extends Controller {

  connect() {
    window.setTimeout(_ => this.close(), 5000);
  }

  close(ev) {
    if (typeof ev !== "undefined") ev.preventDefault();
    this.element.remove();
  }
}
