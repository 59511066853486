import { Controller } from "@hotwired/stimulus";
import { useDebounce } from "stimulus-use";

// Connects to data-controller="scenarios--calc-test-histories--documents"
export default class extends Controller {
  static debounces = ["filterValues", "markRowsWithDiff"];
  static targets = [
    "compareValues",
    "showValues",
    "filterValue",
    "head",
    "body"
  ];

  connect() {
    useDebounce(this, {wait: 300});
    this.applyFilters();
    this.applyDiffClass();
    this.markRowsWithDiff();
  }

  applyDiffClass() {
    this.rows.forEach(rowEl => {
      if (this.compareColumns) {
        this._diffBetweenExpectedAndCalculated(rowEl);
      } else {
        this._diffBetweenColumnsOfType(rowEl, "input");
        this._diffBetweenColumnsOfType(rowEl, "expected");
        this._diffBetweenColumnsOfType(rowEl, "calculated");
      }
    });
  }

  applyFilters() {
    // Show only columns the user will use to compare.
    this.compareValues();
    // Show only values that match the requirement selected by the user on visible cells filtered in the previous step.
    this.showValues();
    // Show only rows that match the field's name with the user search.
    this.filterValues();
    // Mark Visible Rows that have Differences
    this.markRowsWithDiff();
  }

  compareValues() {
    const column = this.compareValuesTarget.value;
    if (column !== "") {
      this.bodyTarget.querySelectorAll("td:not(.document-field):not(.document-" + column + ")").forEach(el => el.classList.add("hidden"));
      this.bodyTarget.querySelectorAll("td.document-" + column).forEach(el => el.classList.remove("hidden"));
      this.headTarget.querySelectorAll("th:not(.document-field):not(.document-" + column + ")").forEach(el => el.classList.add("hidden"));
      this.headTarget.querySelectorAll("th.document-" + column).forEach(el => el.classList.remove("hidden"));
    }
  }

  showValues() {
    const value = this.showValuesTarget.value;
    switch (value) {
      case "differences":
        this._showOnlyValuesWithDifferences();
        break;
      case "blank":
        this._showOnlyBlankValues();
        break;
      case "present":
        this._showOnlyPresentValues();
        break;
      default:
        this._showAllValues();
    }
  }

  filterValues() {
    const search = this.filterValueTarget.value;
    this.rowsVisibleByScope.forEach(rowEl => {
      const fieldEl = rowEl.querySelector(".document-field");
      const fieldPath = fieldEl.dataset.originalValue;
      const textMatch = this._compareText(fieldPath, search);
      rowEl.classList.toggle("hidden", textMatch === false);
      if (textMatch === true) {
        const hiperLinkLabels = this._applyMarkText(fieldPath, search).split(".");
        const hiperLinkValues = fieldPath.split(".");

        fieldEl.innerHTML = hiperLinkValues.map(item => {
          const index = hiperLinkValues.indexOf(item);
          const valor = hiperLinkValues.slice(0, index + 1).join(".");
          const label = hiperLinkLabels[index];
          return `<a data-action="click->scenarios--calc-test-histories--documents#searchByClick" data-column-value="${valor}" >${label}</a>`;
        }).join(".");

      }
    });
  }

  clearInput() {
    this.filterValueTarget.value = "";
    this.filterValues();
  }

  searchByClick(event) {
    this.filterValueTarget.value = event.currentTarget.dataset.columnValue;
    this.filterValues();
  }

  markRowsWithDiff() {
    this.bodyTarget.querySelectorAll(".document-row.has-differences").forEach(row => {
      row.classList.remove("has-differences");
      row.classList.remove("stopped-point");
    });
    this.rows.forEach(row => {
      const visibleCellWithDiff = row.querySelector(".table-danger:not(.hidden)");
      if (visibleCellWithDiff) {
        row.classList.add("has-differences");
      }
    });
  }

  _diffBetweenExpectedAndCalculated(rowEl) {
    const expectedEl = rowEl.querySelector(".document-expected");
    const calculatedEl = rowEl.querySelector(".document-calculated");
    if (expectedEl.textContent !== "" && expectedEl.textContent !== calculatedEl.textContent) {
      //TODO: BS3 = Danger, BS5 = table-danger
      expectedEl.classList.add("danger");
      expectedEl.classList.add("table-danger");
      calculatedEl.classList.add("danger");
      calculatedEl.classList.add("table-danger");
    }
  }

  _diffBetweenColumnsOfType(rowEl, columnType) {
    const selector = ".document-" + columnType;
    const values = this._valuesFrom(rowEl, selector);
    if (this._differentFromEachOther(values)) {
      rowEl.querySelectorAll(selector).forEach(element => {
        //TODO: BS3 = Danger, BS5 = table-danger
        element.classList.add("danger");
        element.classList.add("table-danger");
      });
    }
  }

  _compareText(textA, textB) {
    let pattern = new RegExp(textB, "gi");
    return pattern.test(textA);
  }

  _applyMarkText(originalValue, searchValue) {
    if (searchValue === "") {
      return originalValue;
    }
    return originalValue.split(".").map(oItem => {
      let search = searchValue.split(".");

      for (let index in search) {
        let pattern = new RegExp("(" + search[index] + ")", "gi");
        if (oItem.match(pattern) !== null) {
          return oItem.replace(pattern, "<mark>$1</mark>");
        }
      }
      return oItem;

    }).join(".");
  }

  _showOnlyValuesWithDifferences() {
    this.rows.forEach(rowEl => {
      const values = this._visibleColumnsValues(rowEl);
      const isDifferent = this.compareColumns ? this._expectedIsDifferentFromCalculated(values) : this._differentFromEachOther(values);
      rowEl.classList.toggle("hidden", isDifferent === false);
      rowEl.classList.toggle("hidden-by-scope", isDifferent === false);
    });
  }

  _showOnlyBlankValues() {
    this.rows.forEach(rowEl => {
      const presentRow = this._visibleColumnsValues(rowEl).some(value => value !== "");
      rowEl.classList.toggle("hidden", presentRow);
      rowEl.classList.toggle("hidden-by-scope", presentRow);
    });
  }

  _showOnlyPresentValues() {
    this.rows.forEach(rowEl => {
      const blankRow = this._visibleColumnsValues(rowEl).every(value => value === "");
      rowEl.classList.toggle("hidden", blankRow);
      rowEl.classList.toggle("hidden-by-scope", blankRow);
    });
  }

  _showAllValues() {
    this.rows.forEach(rowEl => {
      rowEl.classList.remove("hidden");
      rowEl.classList.remove("hidden-by-scope");
    });
  }

  _visibleColumnsValues(rowEl) {
    return this._valuesFrom(rowEl, "td:not(.document-field):not(.hidden)");
  }

  _valuesFrom(element, selector) {
    return Array.from(element.querySelectorAll(selector)).map(el => el.textContent);
  }

  _expectedIsDifferentFromCalculated(values) {
    const [_input, expected, calculated] = values;
    return expected !== "" && expected !== calculated;
  }

  _differentFromEachOther(values) {
    return values.every(value => value === values[0]) === false;
  }

  get rows() {
    if (this._rows === undefined) {
      this._rows = this.bodyTarget.querySelectorAll(".document-row");
    }
    return this._rows;
  }

  get compareColumns() {
    if (this._compareColumns === undefined) {
      const expectedEl = this.rows[0].querySelector(".document-expected:not(.hidden)");
      const calculatedEl = this.rows[0].querySelector(".document-calculated:not(.hidden)");
      this._compareColumns = expectedEl && calculatedEl;
    }
    return this._compareColumns;
  }


  get rowsVisibleByScope() {
    return this.bodyTarget.querySelectorAll(".document-row:not(.hidden-by-scope)");
  }

  get rowsVisible() {
    return this.bodyTarget.querySelectorAll(".document-row:not(.hidden)");
  }

}
