import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="comments--form"
export default class extends Controller {
  static targets = ["content"];
  connect() {
    const that = this;
    this.element.addEventListener('turbo:submit-end', (event) => {
      if (event.detail.success) {
        this.contentTarget.value = ""
        that.dispatch("updated")
      }
    })
  }

  submit() {
    this.element.requestSubmit()
  }
}
