import { Controller } from "@hotwired/stimulus";
import { useMemo } from "stimulus-use";
import Offcanvas from "bootstrap-5-3/js/dist/offcanvas";

export default class extends Controller {
  static memos = ["offCanvasInstance", "offCanvasElement"];
  connect() {
    useMemo(this);

    this.offCanvasInstance.show();
    this.offCanvasElement.addEventListener("hidden.bs.offcanvas", event => {
      event.target.remove();
    });
  }

  disconnect() {
    this.offCanvasInstance.dispose();
  }

  close() {
    this.offCanvasInstance.hide();
  }

  get offCanvasInstance() {
    return new Offcanvas(this.offCanvasElement);
  }

  get offCanvasElement() {
    return this.element;
  }

}