import { Controller } from "@hotwired/stimulus"
import { useDebounce } from 'stimulus-use'

// Connects to data-controller="monitor-legal--tax-laws--form"
export default class extends Controller {
  static debounces = ['updateCode'];
  static targets = ["code", "form"];

  connect() {
    useDebounce(this, { wait: 300 })

    const that = this
    this.element.addEventListener('turbo:submit-end', event => {
      if (event.detail.success) {
        that.dispatch("submitted")
      }
    })
  }

  updateCode({ params }) {
    const queryString = new URLSearchParams(new FormData(this.formTarget)).toString()
    fetch(`${params.url}?${queryString}`)
    .then(response => response.json())
    .then(data => this.codeTarget.innerHTML = data.code)
  }

  submit() {
    this.element.requestSubmit()
  }
}
