import React from 'react';

import { makeStyles } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';

import DataView from './DataView';

const useStyles = makeStyles({
  toolbar: {
    display:"flex",
    justifyContent:"space-between"
  },
})

function DrawerDataView({links, onClose}) {
  const classes = useStyles();

  const handleClose = event => onClose(event);
  const handleOpenNewWindow = event => {
    event.stopPropagation();
    window.open(links.app_url);
  }

  return (
    <Drawer anchor='right' open={true} onClose={handleClose}>
      <Toolbar variant="dense" className={classes.toolbar}>
        <IconButton edge="start" color="inherit" onClick={handleClose}>
          <CloseIcon />
        </IconButton>
        <IconButton color="inherit" onClick={handleOpenNewWindow}>
          <OpenInNewIcon />
        </IconButton>
      </Toolbar>
      <DataView dataUrl={links.api_data_url} />
    </Drawer>
  );
}

export default DrawerDataView;