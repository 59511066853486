import { Controller } from "@hotwired/stimulus"
import { updateUrlParam, removeUrlParam } from "../../utils/url"
import { toggleDisabled } from "../../utils/visibility";

// Connects to data-controller="accounts--products--table"
export default class extends Controller {
  static targets = [
    "btnActionsMenu",
    "selectedProductsInput",
    "searchParamsInput",
    "accountNameInput",
    "deleteButton",
    "modalForm",
    "totalProductsCounter"
  ];

  static values = {account: String}
  static memos = [
    'buttonTargets'
  ]

  connect() {}

  onCheckboxChange({ detail: { pageValues, searchAllChecked, searchParamValues }}) {
    let selectedIds = pageValues
    this._disableButtons(selectedIds.length < 1 && searchAllChecked === false)

    if (searchAllChecked) {
      this._removeUrlParam('ids');
      this._updateUrlParam('search', searchParamValues);
      this.selectedProductsInputTargets.forEach(target => target.value = "")
      this.searchParamsInputTargets.forEach(target => target.value = searchParamValues)

      this.totalProductsCounterTarget.textContent = this.totalProductsCounterTarget.dataset.allProductsWarning;

    } else {
      this._removeUrlParam('search');
      this._updateUrlParam('ids', selectedIds);
      this.selectedProductsInputTargets.forEach(target => target.value = selectedIds)
      this.searchParamsInputTargets.forEach(target => target.value = "")

      const warningTemplate = this.totalProductsCounterTarget.dataset.productWarning;
      this.totalProductsCounterTarget.textContent = warningTemplate.replace('%{total}', selectedIds.length);
    }
  }


  _updateUrlParam(paramName, values) {
    this.btnActionsMenuTargets.forEach(target => target.href = updateUrlParam(target.href, paramName, values))

  }

  _removeUrlParam(paramName) {
    this.btnActionsMenuTargets.forEach(target => target.href = removeUrlParam(target.href, paramName))
  }

  _disableButtons(disabled = false) {
    this.btnActionsMenuTargets.forEach(target => toggleDisabled(target, disabled))
  }

  disableDeleteButton(){
    toggleDisabled(this.deleteButtonTarget, !(this.accountNameInputTarget.value === this.deleteButtonTarget.dataset["value"]))
  }

}
