export const updateIdsOnTargetUrl = (target, ids) => {
  if (typeof(target) === 'undefined') return;

  const url = new URL(target.href);
  url.searchParams.set('ids', ids);
  target.href = url;
}

export const updateUrlParam = (targetUrl, attribute, values) => {
  if (typeof(targetUrl) === 'undefined' || targetUrl === "") return;

  const url = new URL(targetUrl);
  url.searchParams.set(attribute, values);
  return url.toString();
}

export const removeUrlParam = (targetUrl, attribute) => {
  if (typeof(targetUrl) === 'undefined' || targetUrl === "") return;

  const url = new URL(targetUrl);
  url.searchParams.delete(attribute);
  return url.toString();
}