import { Controller } from "@hotwired/stimulus";
import { useMutation } from "stimulus-use";
import { Tooltip } from "../../vendor/mosaic_css/js/mdb.pro";

// Connects to data-controller="mosaic-bs--tooltip"
export default class extends Controller {
  _previousPosition = {top: 0, left: 0};

  connect() {
    useMutation(this, {
      element: this.element,
      attributes: true,
      attributeFilter: ["style"]
    });
    this.tooltipInstance;
    this._previousPosition = this.elementPosition;
  }

  mutate(entries) {
    entries.forEach(entry => {
      if (entry.type === "attributes" && entry.attributeName === "style") {
        const newPosition = this.elementPosition;
        if (newPosition.top !== this._previousPosition.top || newPosition.left !== this._previousPosition.left) {
          this._previousPosition = newPosition;
          this.tooltipInstance.hide();
        }
      }
    });
  }

  disconnect() {
    this._tooltip.dispose();
  }

  get tooltipInstance() {
    if (this._tooltip === undefined) {
      this._tooltip = new Tooltip(this.element, {
        boundary: "window",
        trigger: "hover focus",
        html: true
      });
    }
    return this._tooltip;
  }

  get elementPosition() {
    const rect = this.element.getBoundingClientRect();
    return {top: rect.top, left: rect.left};
  }
}
