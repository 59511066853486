import { Controller } from "@hotwired/stimulus"
import { updateUrlParam, removeUrlParam } from "../../utils/url"
import { toggleDisabled } from "../../utils/visibility";

// Connects to data-controller="accounts--calc-tests--table"
export default class extends Controller {
  static targets = [
    "btnAcceptSelected",
    "btnExcluir",
    "btnCalcular",
    "btnAlterarGrupo",
    "btnExportCalcTests",
    "btnUpdateParams",
    "allRecordsUpdate"
  ];
  static memos = [
    'buttonTargets'
  ]

  connect() {}

  onCheckboxChange({ detail: { pageValues, searchAllChecked, searchParamValues }}) {
    this._disableButtons(pageValues.length < 1 && searchAllChecked === false)

    if (searchAllChecked) {
      this._removeUrlParam('ids');
      this._updateUrlParam('search', searchParamValues);
      this._updateParamsIds('');
    } else {
      this._removeUrlParam('search');
      this._updateUrlParam('ids', pageValues);
      this._updateParamsIds(pageValues);
    }
    this._setFlagAllRecordsSelected(searchAllChecked)
  }

  _setFlagAllRecordsSelected(searchAllChecked){
    const allRecords = searchAllChecked ? 1 : 0;
    const all_records_update = document.getElementById('all_records_update')
    all_records_update.value = allRecords;
  }

  _updateParamsIds(values) {
    const checkedIdUpdate = document.getElementById('checked_ids_update')
    checkedIdUpdate.value = values;
  }

  _updateUrlParam(paramName, values) {
    this.buttonTargets.forEach(target => target.href = updateUrlParam(target.href, paramName, values))
  }

  _removeUrlParam(paramName) {
    this.buttonTargets.forEach(target => target.href = removeUrlParam(target.href, paramName))
  }

  _disableButtons(disabled = false) {
    this.buttonTargets.forEach(target => toggleDisabled(target, disabled))
    toggleDisabled(document.getElementById('btn_update_params'), disabled)
  }

  get buttonTargets() {
    let buttons = []
    if (this.hasBtnAcceptSelectedTarget) buttons.push(this.btnAcceptSelectedTarget)
    if (this.hasBtnExcluirTarget) buttons.push(this.btnExcluirTarget)
    if (this.hasBtnCalcularTarget) buttons.push(this.btnCalcularTarget)
    if (this.hasBtnAlterarGrupoTarget) buttons.push(this.btnAlterarGrupoTarget)
    if (this.hasBtnExportCalcTestsTarget) buttons.push(this.btnExportCalcTestsTarget)
    if (this.hasBtnUpdateParamsTarget) buttons.push(this.btnUpdateParamsTarget)
    return buttons;
  }

}
