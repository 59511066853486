import { Controller } from "@hotwired/stimulus";
import { SwalConfirm } from "@plugins/sweetalert2";

// Connects to data-controller="accounts--calc-tests--search"
export default class extends Controller {
  static outlets = ["checkbox"];
  static targets = ["form"];
  static values = {
    confirmationTitle: {type: String, default: ""},
    confirmationLabel: {type: String, default: "Are you sure?"}
  };

  search(_event) {
    this.formTarget.requestSubmit();
  }

  confirmSubmit(event) {
    event.preventDefault();
    if (this.hasCheckboxOutlet === false) return;
    if (this.checkboxOutlet.totalChecked > 0 || this.checkboxOutlet.checkboxAllChecked || this.checkboxOutlet.checkboxSearchAllChecked) {
      SwalConfirm.fire({
        title: this.confirmationTitleValue,
        html: this.confirmationLabelValue
      }).then(action => {
        if (action.isConfirmed) {
          this.checkboxOutlet.clearCheckedValues();
          this.checkboxOutlet.clearAllSearch();
          this.formTarget.requestSubmit();
        }
      });
    }
    return false;
  }
}
