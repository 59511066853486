import {Controller} from "@hotwired/stimulus";
import Sortable from "sortablejs"

export default class extends Controller {
    connect() {
        this.sortable = Sortable.create(this.element, {
            group: 'shared',
            animation: 150,
            onEnd: this.end.bind(this)
        })
    }

    end(event) {
        // nothing todo
    }
}