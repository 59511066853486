import { Controller } from "@hotwired/stimulus";
import TurboForm from "../utils/turbo_form";

// Connects to data-controller="mosaic-bs--toolbar"
export default class extends Controller {
  static outlets = [
    "mosaic-bs--checkbox",
    "mosaic-bs--form-search"
  ];
  static targets = [
    "bulkActions",
    "selectedText",
    "selectAllSearch",
    "cancelSelectAllSearch",
    "formAction"
  ];
  static values = {
    totalCount: {type: Number, default: 0},
    selectedText: {type: String, default: ""},
    selectedAllSearch: {type: Boolean, default: false},
    checked: {type: Array, default: []}
  };

  //~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
  // Toolbar targets
  //~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
  selectAllSearchTargetConnected(element) {
    element.addEventListener("click", _event => this.toggleAllSearch());
  }

  selectAllSearchTargetDisconnected(element) {
    element.removeEventListener("click", _event => this.toggleAllSearch());
  }

  cancelSelectAllSearchTargetConnected(element) {
    element.addEventListener("click", _event => this.cancelAllSearch());
  }

  cancelSelectAllSearchTargetDisconnected(element) {
    element.removeEventListener("click", _event => this.cancelAllSearch());
  }

  formActionTargetConnected(element) {
    element.addEventListener("click", event => this.formActionHandler(event));
  }

  formActionTargetDisconnected(element) {
    element.removeEventListener("click", event => this.formActionHandler(event));
  }

  //~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
  // Toolbar handlers
  //~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
  formActionHandler(event) {
    event.preventDefault();
    const turboForm = new TurboForm(event.currentTarget);
    turboForm.addFields([
      {name: "selection[search_type]", value: this.searchType},
      {name: "selection[search_value]", value: this.searchValue},
      {name: "selection[search_count]", value: this.searchCount}
    ]);
    turboForm.submit();
  }

  //~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
  // Toolbar events/actions
  //~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
  openBulkActions() {
    this.bulkActionsTarget.classList.remove("closed");
    this.bulkActionsTarget.classList.add("open");
  }

  closeBulkActions() {
    this.bulkActionsTarget.classList.add("closed");
    this.bulkActionsTarget.classList.remove("open");
  }

  toggleAllSearch() {
    this.selectedAllSearchValue = !this.selectedAllSearchValue;
    this.setSelectedText(this.searchCount);
    this.setAllCheckboxToIndeterminate(this.searchByFilters);
  }

  cancelAllSearch() {
    this._resetBulkActions();
  }

  unselectAllSearch() {
    this.setSelectedText("...");
    this.selectedAllSearchValue = false;
    this.setAllCheckboxToIndeterminate(false);
  }

  setSelectedText(total) {
    this.selectedTextTarget.innerHTML = `${total} ${this.selectedTextValue}`;
  }

  //~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
  // Toolbar Helpers
  //~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
  _updateCheckedValue(value, checked) {
    const setValues = new Set(this.checkedValue);
    const values = Array.isArray(value) ? value : [value];
    values.forEach(val => (checked ? setValues.add(val) : setValues.delete(val)));
    this.checkedValue = Array.from(setValues);
  }

  _resetBulkActions() {
    this.checkedValue = [];
    this.uncheckAllCheckbox();
    this.unselectAllSearch();
    this.closeBulkActions();
  }

  //~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
  // Toolbar Getters and Setters
  //~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
  get searchRecordsCount() {
    return this.totalCountValue;
  }

  get searchType() {
    return this.searchByFilters ? "filters" : "ids";
  }

  get searchValue() {
    return this.searchByFilters ? this.formQueryString : this.checkedValue.join(",") ;
  }

  get searchCount() {
    return this.searchByFilters ? this.searchRecordsCount : this.checkedCount;
  }

  get checkedCount() {
    return this.checkedValue.length;
  }

  get hasChecked() {
    return this.checkedCount > 0;
  }

  get searchByFilters() {
    return this.selectedAllSearchValue === true;
  }

  //~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
  // Outlet Search events
  //~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
  mosaicBsFormSearchOutletConnected(outlet, element) {
    element.addEventListener("submit", (event) => this.handleFormSubmitEvent(event));
  }

  handleFormSubmitEvent(event) {
    if (this.hasMosaicBsFormSearchOutlet === false) return;

    if (this.mosaicBsFormSearchOutlet.submitConfirmed === false && (this.hasChecked || this.searchByFilters)) {
      this.mosaicBsFormSearchOutlet.confirmSubmit(event, action => {
        if (!action.isConfirmed) return;

        this._resetBulkActions();
      });
    }
  }

  get formQueryString() {
    if (this.hasMosaicBsFormSearchOutlet === false) return "";

    return this.mosaicBsFormSearchOutlet.formDataQueryString;
  }


  //~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
  // Outlet Checkbox events
  //~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
  mosaicBsCheckboxOutletConnected(outlet, element) {
    element.addEventListener("one-checked", (event) => this.handleCheckboxEvent(event));
    element.addEventListener("all-checked", (event) => this.handleCheckboxEvent(event));
    window.setTimeout(_ => {
      // Refresh Checkbox States
      // outlet.uncheckAll();
      if (this.searchByFilters) {
        outlet.setAllCheckboxIndeterminate(true);
      } else {
        outlet.checkboxAllTarget.checked = false;
        outlet.setCheckedByValue(this.checkedValue);
      }
    }, 50);
  }

  mosaicBsCheckboxOutletDisconnected(outlet, element) {
    element.removeEventListener("one-checked", (event) => this.handleCheckboxEvent(event));
    element.removeEventListener("all-checked", (event) => this.handleCheckboxEvent(event));
  }

  handleCheckboxEvent(event) {
    if (this.hasMosaicBsCheckboxOutlet === false) return;

    this.unselectAllSearch();
    const {value, checked} = event.detail;
    this._updateCheckedValue(value, checked);
    this.refresh();
  }

  setAllCheckboxToIndeterminate(searchAllChecked) {
    if (this.hasMosaicBsCheckboxOutlet === false) return;

    this.mosaicBsCheckboxOutlet.setAllCheckboxIndeterminate(searchAllChecked);
  }

  uncheckAllCheckbox() {
    if (this.hasMosaicBsCheckboxOutlet === false) return;

    this.mosaicBsCheckboxOutlet.uncheckAll();
  }

  uncheckAllSearch() {

  }

  refresh() {
    if (this.hasChecked) {
      this.openBulkActions();
      this.setSelectedText(this.checkedCount);
    } else {
      this.closeBulkActions();
    }
  }
}