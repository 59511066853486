export const replyEvent = (originalEvent, element, customName = "", opts = {}) => {
  if (customName === "") customName = originalEvent.type;
  opts = Object.assign(opts, {
    bubbles: true,
    cancelable: true,
    detail: originalEvent.detail
  });
  element.dispatchEvent(new CustomEvent(customName, opts));
};

export const triggerEvent = (element, eventName, detail = {}, opts = {}) => {
  element.dispatchEvent(new CustomEvent(eventName, {
    bubbles: true,
    cancelable: true,
    ...opts,
    detail: detail
  }));
};