import React from 'react';

import Grid from '@material-ui/core/Grid';
import Skeleton from '@material-ui/lab/Skeleton';
import Typography from '@material-ui/core/Typography';

function RuntimeInfoItem(props) { 
   const {
    label,
    value,
    loading
  } = props;

  return (
    <Grid container>
      <Grid item xs={4}>
        <Typography noWrap variant="subtitle1">
          {label}
        </Typography>
      </Grid>
      <Grid item xs={8}>
        <Typography noWrap variant="h6">
          {loading ? <Skeleton variant="text" /> : value}
        </Typography>
      </Grid>
    </Grid>
  )
}

export default RuntimeInfoItem;