export const locale = {
  "pt-BR": {
    nextMonth: "<svg width=\"8\" height=\"12\" viewBox=\"0 0 8 12\" fill=\"none\" xmlns=\"http://www.w3.org/2000/svg\"><path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M1.6998 0L0.299805 1.4L4.8998 6L0.299805 10.6L1.6998 12L7.6998 6L1.6998 0Z\" fill=\"#545E75\"/></svg>",
    previousMonth: "<svg width=\"8\" height=\"12\" viewBox=\"0 0 8 12\" fill=\"none\" xmlns=\"http://www.w3.org/2000/svg\"><path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M7.6998 1.4L6.2998 0L0.299805 6L6.2998 12L7.6998 10.6L3.0998 6L7.6998 1.4Z\" fill=\"#545E75\"/></svg>",
    cancel: "Cancelar",
    apply: "Aplicar"
  },
  "en": {
    nextMonth: "<svg width=\"8\" height=\"12\" viewBox=\"0 0 8 12\" fill=\"none\" xmlns=\"http://www.w3.org/2000/svg\"><path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M1.6998 0L0.299805 1.4L4.8998 6L0.299805 10.6L1.6998 12L7.6998 6L1.6998 0Z\" fill=\"#545E75\"/></svg>",
    previousMonth: "<svg width=\"8\" height=\"12\" viewBox=\"0 0 8 12\" fill=\"none\" xmlns=\"http://www.w3.org/2000/svg\"><path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M7.6998 1.4L6.2998 0L0.299805 6L6.2998 12L7.6998 10.6L3.0998 6L7.6998 1.4Z\" fill=\"#545E75\"/></svg>",
    cancel: "Cancelar",
    apply: "Aplicar"
  }
};