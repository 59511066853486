import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="template-replication"
export default class extends Controller {

  static targets = [ "initializer", "container", "item", "model" ]

  static values = { reference: String }
  connect() {
    this.build()
    this.add()
  }

  build() {
    const initializerNode = this.initializerTarget.content;
    initializerNode.querySelectorAll("template.template-replication-item").forEach(el => this._addElement(el))
    const newElement = initializerNode.querySelector("template.template-replication-new-item")
    this.modelTarget.innerHTML = newElement.innerHTML
  }

  add(_ev) {
    this._addElement(this.modelTarget)
  }

  _addElement(sourceElement) {
    const modelNode = this._newNode(sourceElement)
    const itemNode = this._newNode(this.itemTarget)
    itemNode.prepend(modelNode)
    this.containerTarget.append(itemNode)
  }

  remove(ev) {
    const item = ev.target.closest(".template-replication-item")
    item.remove()
  }

  _newNode(target) {
    const node = document.createElement('div');
    node.innerHTML = target.innerHTML.trim()
    return node.firstChild
  }
}
