import { Controller } from "@hotwired/stimulus";
import { SwalConfirm } from "@plugins/sweetalert2";

// Connects to data-controller="form-search"
export default class extends Controller {
  static outlets = ["checkbox"];
  static targets = ["form", "submitter"];
  static values = {
    resetAfterSubmit: {type: Boolean, default: false},
    confirmationLabel: {type: String, default: "Are you sure?"}
  };

  connect() {
    if (this.resetAfterSubmitValue) {
      this.formTarget.addEventListener("turbo:submit-end", event => this.clear(event));
    }
  }

  submitterTargetConnected(element) {
    element.addEventListener("change", event => this.search(event));
  }

  search(_event) {
    this.formTarget.requestSubmit();
  }

  clear(_event) {
    this.formTarget.reset();
    // Select2 Compatibility
    this.formTarget.querySelectorAll(".select2-input").forEach(input => input.dispatchEvent(new Event("change")));
  }

  confirmSubmit(event) {
    event.preventDefault();

    if (this.hasCheckboxOutlet === false) return;
    if (this.checkboxOutlet.totalChecked > 0 || this.checkboxOutlet.checkboxAllChecked || this.checkboxOutlet.checkboxSearchAllChecked) {
      let that = this;
      SwalConfirm.fire({
        html: this.confirmationLabelValue
      }).then(action => {
        if (action.isConfirmed) {
          that.checkboxOutlet.clearCheckedValues();
          that.checkboxOutlet.clearAllSearch();
          that.formTarget.requestSubmit();
        }
      });
    }
  }
}