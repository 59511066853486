import { Controller } from "@hotwired/stimulus"


// Connects to data-controller="products--show"



export default class extends Controller {

    static targets = [
        "ncm",
        "exNcm",
        "exTipi",
        "exTec",
        "originalNcm",
        "originalExNcm",
        "originalExTipi",
        "originalExTec",
        "cest",
        "originalCest"
    ]

    connect() {
        var ncm_code = this.ncmTarget.dataset.value
        var original_ncm_code = this.originalNcmTarget.dataset.value

        this.show_ncm(this.ncmTarget);
        this.show_ncm(this.originalNcmTarget);

        this.show_ex_ncm(ncm_code, this.exNcmTarget);
        this.show_ex_ncm(ncm_code, this.exTipiTarget);
        this.show_ex_ncm(ncm_code, this.exTecTarget);

        this.show_ex_ncm(original_ncm_code, this.originalExNcmTarget);
        this.show_ex_ncm(original_ncm_code, this.originalExTipiTarget);
        this.show_ex_ncm(original_ncm_code, this.originalExTecTarget);

        this.show_cest_options(this.cestTarget)
        this.show_cest_options(this.originalCestTarget)
    }

    show_ncm(ncm_target) {

        var ncm_val = ncm_target.dataset.value;

        $.ajax(
            '/ncm_description/' + ncm_val,
            {
                cache: false,
                beforeSend: function (result) {
                    $(ncm_target).html('Carregando descrição...');
                },
                success: function (result) {
                    $(ncm_target).html(result);
                },
                error: function (result) {
                    $(ncm_target).html('Ops...');
                }
            });
    }

    show_ex_ncm(ncm_code, target) {
        var ex_ncm_val = target.dataset.objectExncm;
        let type = target.dataset.objectType
        if(ex_ncm_val !== undefined && type !== undefined){
            ex_ncm_val = ex_ncm_val.replace('.', '_')

            if (ex_ncm_val == '') {
                target.html('');
            } else {
                $.ajax(
                    '/ncm_short_description/' + ncm_code + '/' + type + '/' + ex_ncm_val,
                    {
                        cache: false,
                        beforeSend: function (result) {
                            $(target).html('Carregando descrição...');
                        },
                        success: function (result) {
                            $(target).html(result);
                        },
                        error: function (result) {
                            $(target).html('Ops...');
                        }
                    });
            }
        }
    }


    show_cest_options(target) {
        let cest = target.dataset.objectCest
        $.ajax({
            url: target.dataset.objectUrl,
            data: {cest: cest},
            cache: false,
            beforeSend: function (result) {
                $(target).html('Carregando...');
            },
            success: function (data) {
                if (data.length > 0){

                    data.forEach( item => {
                        $(target).html(`<li> ${item['description']} </li>`);
                    })

                }
                else {
                    if (cest !== undefined && cest.trim().length > 0) {
                        $(target).html('<li>O CEST ' + cest + ' NÃO é válido.</li>');
                    }
                    else{
                        $(target).html('');
                    }
                }
            },
            error: function (result) {
                $(target).html('Ops...');
            }
        });
    }
}

