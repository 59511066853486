import { Controller } from "@hotwired/stimulus";
import { addValueToList, delValueFromList } from "../utils/array";

// Connects to data-controller="mosaic-bs--chip-filter"
export default class extends Controller {
  static outlets = ["mosaic-bs--form-search"];
  static values = {
    activeClass: {type: String, default: "chip-active"},
    multiple: {type: Boolean, default: false},
    listSeparator: {type: String, default: ","},
    fieldName: String,
    fieldValue: String
  };

  connect() {
    this.element.addEventListener("click", _ => this.toggle());
  }

  toggle() {
    this.isActive ? this.unsetFormFieldValue() : this.setFormFieldValue();
  }

  activate() {
    this.element.classList.add(this.activeClassValue);
  }

  deactivate() {
    this.element.classList.remove(this.activeClassValue);
  }

  checkActivation() {
    this.isInactive ? this.deactivate() : this.activate();
  }

  mosaicBsFormSearchOutletConnected(_outlet, _element) {
    this.checkActivation();
  }

  setFormFieldValue() {
    if (this.hasMosaicBsFormSearchOutlet === false) return;

    const values = this.multipleValue ? addValueToList(this.formFieldValue, this.fieldValueValue) : this.fieldValueValue;
    this.mosaicBsFormSearchOutlet.setFieldValueByName(this.fieldNameValue, values);
    this.searchForm();
  }

  unsetFormFieldValue() {
    if (this.hasMosaicBsFormSearchOutlet === false) return;

    if (this.multipleValue) {
      const values = delValueFromList(this.formFieldValue, this.fieldValueValue);
      this.mosaicBsFormSearchOutlet.setFieldValueByName(this.fieldNameValue, values);
    } else {
      this.mosaicBsFormSearchOutlet.unsetFieldByName(this.fieldNameValue);
    }
    this.searchForm();
  }

  searchForm() {
    if (this.hasMosaicBsFormSearchOutlet === false) return;

    this.mosaicBsFormSearchOutlet.search(new Event("click", this.element));
  }

  get formFieldValue() {
    if (this.hasMosaicBsFormSearchOutlet === false) return;

    return this.mosaicBsFormSearchOutlet.getFieldValueByName(this.fieldNameValue);
  }

  get isActive() {
    return this.isInactive === false;
  }

  get isInactive() {
    const value = this._toStringLowerValue(this.formFieldValue);
    const formValue = this._toStringLowerValue(this.fieldValueValue);
    const contains = this.multipleValue ? value.includes(formValue) : value === formValue;

    return value === "" || contains === false;
  }

  _toStringLowerValue(value) {
    if (this.multipleValue) {
      const values = (typeof value === undefined) || value === null ? [] : value;
      return values.split(this.listSeparatorValue).map(item => {
        const value = (typeof item === undefined) || item === null ? "" : String(item);
        return value.trim().toLowerCase();
      }).join(this.listSeparator);
    } else {
      value = (typeof value === undefined) || value === null ? "" : String(value);
      return value.toLowerCase();
    }
  }
};