import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from "prop-types";
import axios from "axios";

import Button from '@material-ui/core/Button';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Skeleton from '@material-ui/lab/Skeleton';

import RuntimeInfo from './runtime/RuntimeInfo'
import RuntimeStatusIcon from './runtime/RuntimeStatusIcon'

const useStyles = makeStyles((theme) => ({
  root: {
    with: 'auto',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-around',
    alignItems: 'center',
    padding: '10px',
  },
  details: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
  },
  detailsText: {
    maxWidth: 200,
    textTransform: 'lowercase',
  },
  tooltip: {
    fontSize: '12pt',
    backgroundColor: theme.palette.common.black,
  },
}));

function RuntimeStatusWidget(props) {
  const {
    runtimeUrl,
    runtimeName,
    gotoRuntimeUrl,
    checkInterval,
    runtimeVersion
  } = props;
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState(null);
  const [isWorking, setWorking] = useState(false);
  const [showRuntimeInfo, setShowRuntimeInfo] = useState(false);
  const [runtime, setRuntime] = useState({
    tax_rules: {label: '', value: runtimeVersion},
    runtime_status: {label: '', value: ''}
  });

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleInfo = _ => {
    closeMenu()
    setShowRuntimeInfo(true);
  }

  const handleGoToTaxgateway = _ => {
    window.open(gotoRuntimeUrl, "_blank")
    closeMenu()
  }

  const handleGoToRuntime = _ => {
    window.location = runtimeUrl;
    closeMenu()
  }

  const handleReload = _ => {
    closeMenu()
    getStatus()
  }

  const handleCloseRuntimeInfo = _ => {
    setShowRuntimeInfo(false);
  };

  const closeMenu = _ => {
    setAnchorEl(null);
  };

  function getStatus(autoReload=false) {
    setWorking(true);
    return axios({
        url: runtimeUrl,
        method: 'get',
        headers: {'accept': 'application/json'}
      })
      .then(response => {
        // console.log(response);
        setRuntime(response.data)
      })
      .catch(error => {
        // console.log(error)
        setRuntime({})
      }).then(_ => {
        setWorking(false)
        if (autoReload) {
          setTimeout(() => getStatus(autoReload), checkInterval * 1000);
        }
      });
  }

  useEffect(() => {
  }, []);

  return (
    <>
      <RuntimeInfo
        runtimeUrl={runtimeUrl}
        show={showRuntimeInfo}
        onClose={handleCloseRuntimeInfo}

      />
      <Button
        variant="contained"
        color="default"
        disableElevation
        className={classes.root}
        onClick={handleClick}

      >
        <Box ml={1} className={classes.details}>
          <Typography className={classes.detailsText} noWrap variant="body1">
            {runtimeName}
          </Typography>
          <Typography className={classes.detailsText} noWrap variant="caption">
            {isWorking ? <Skeleton variant="text" width={110} /> : runtime.tax_rules?.value}
          </Typography>
        </Box>
      </Button>
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={closeMenu}
      >
        {/* <MenuItem onClick={handleInfo}>Informações</MenuItem> */}
        <MenuItem onClick={handleGoToTaxgateway}>Acessar Taxgateway</MenuItem>
        <MenuItem onClick={handleGoToRuntime}>Informações</MenuItem>
        {!isWorking && <MenuItem onClick={handleReload}>Recarregar</MenuItem>}
      </Menu>
    </>
  );
}

RuntimeStatusWidget.propTypes = {
  runtimeUrl: PropTypes.string.isRequired,
  gotoRuntimeUrl: PropTypes.string.isRequired,
  runtimeName: PropTypes.string.isRequired,
  checkInterval: PropTypes.number,
  runtimeVersion: PropTypes.string.isRequired
};

RuntimeStatusWidget.defaultProps = {
  checkInterval: 5,
};

export default RuntimeStatusWidget;