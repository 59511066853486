import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="accounts--calc-test-insights--batch-acceptance"
export default class extends Controller {
  static targets = [
    "selectionInfoCount",
    "tableBody"
  ];
  static values = {
    accepted: {type: Array, default: []}
  };

  toggleOne(event) {
    const {accepted, fieldPath} = event.target.dataset;
    this._setAccepted(fieldPath, String(accepted) !== "true");
  }

  toggleRow(event) {
    const element = event.target;
    const parentRow = element.closest("tr");
    const accept = parentRow.querySelectorAll(".has-differences.acceptable[data-accepted='false']").length > 0;
    parentRow.querySelectorAll(".has-differences.acceptable").forEach(cell => {
      const {fieldPath} = cell.dataset;
      if (typeof fieldPath !== "undefined") {
        this._setAccepted(fieldPath, accept);
      }
    });
    this._toggleAcceptedClass(element, accept);
  }

  _setAccepted(fieldPath, accept) {
    // console.log(fieldPath, accept);

    this.tableBodyTarget.querySelectorAll(`[data-field-path="${fieldPath}"]`).forEach(cell => {
      this._toggleAcceptedClass(cell, accept);
      if (cell.classList.contains("acceptable")) {
        cell.dataset.accepted = accept;
        this._toggleAcceptedValue(fieldPath, cell.dataset.payload, accept);
      }
    });
  }

  _toggleAcceptedValue(fieldPath, payload, add) {
    const listValues = Array.from(this.acceptedValue);
    const index = listValues.findIndex(item => item.fieldPath === fieldPath);

    if (index === -1 && add) {
      listValues.push({fieldPath, payload});
    } else if (index !== -1 && !add) {
      listValues.splice(index, 1);
    }

    this.acceptedValue = listValues;
    this.selectionInfoCountTarget.innerHTML = this.acceptedValue.length;
  }

  _toggleAcceptedClass(element, accept) {
    accept ? element.classList.add("accepted") : element.classList.remove("accepted");
  }

}
