import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import CircularProgress from "@material-ui/core/CircularProgress";

const useStyles = makeStyles((theme) => ({
  root: {
    position: "relative",
  },
  bottom: {
    color: theme.palette.grey[theme.palette.type === "light" ? 200 : 700],
  },
  top: {
    // color: "#1a90ff",
    animationDuration: "550ms",
    position: "absolute",
    left: 0,
  },
  circle: {
    strokeLinecap: "round",
  },
}));

function CircularProgressWithBorder(props) {
  const { value, size, thickness } = props;
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <CircularProgress
        variant="determinate"
        className={classes.bottom}
        size={size}
        thickness={thickness}
        {...props}
        value={100}
      />
      <CircularProgress
        variant={value > 0 ? "determinate" : "indeterminate"}
        value={value}
        className={classes.top}
        classes={{
          circle: classes.circle,
        }}
        size={size}
        thickness={thickness}
        {...props}
      />
    </div>
  );
}

CircularProgressWithBorder.propTypes = {
  value: PropTypes.number.isRequired,
  size: PropTypes.number,
  thickness: PropTypes.number,
};

CircularProgressWithBorder.defaultProps = {
  value: 0,
  size: 14,
  thickness: 4
};

export default CircularProgressWithBorder;
