import { Controller } from "@hotwired/stimulus"
import { updateUrlParam, removeUrlParam } from "../../utils/url"
import { toggleDisabled } from "../../utils/visibility";

// Connects to accounts--webhook-integrations--table"
export default class extends Controller {
  static targets = [ "btnEnable", "btnDisable", "btnTest"];

  connect() {}

  onCheckboxChange({ detail: { pageValues, searchAllChecked, searchParamValues }}) {
    this._disableButtons(pageValues.length < 1 && searchAllChecked === false)

    if (searchAllChecked) {
      this._removeUrlParam('ids')
      this._updateUrlParam('search', searchParamValues)
    } else {
      this._removeUrlParam('search')
      this._updateUrlParam('ids', pageValues)
    }
  }

  _updateUrlParam(paramName, values) {
    this.btnEnableTarget.href = updateUrlParam(this.btnEnableTarget.href, paramName, values)
    this.btnDisableTarget.href = updateUrlParam(this.btnDisableTarget.href, paramName, values)
    this.btnTestTarget.href = updateUrlParam(this.btnTestTarget.href, paramName, values)
  }

  _removeUrlParam(paramName) {
    this.btnEnableTarget.href = removeUrlParam(this.btnEnableTarget.href, paramName)
    this.btnDisableTarget.href = removeUrlParam(this.btnDisableTarget.href, paramName)
    this.btnTestTarget.href = removeUrlParam(this.btnTestTarget.href, paramName)
  }

  _disableButtons(disabled=false) {
    toggleDisabled(this.btnEnableTarget, disabled)
    toggleDisabled(this.btnDisableTarget, disabled)
    toggleDisabled(this.btnTestTarget, disabled)
  }

}
