import { Controller } from "@hotwired/stimulus";
import { useMemo } from "stimulus-use";
import { Modal } from "../../vendor/mosaic_css/js/mdb.pro";

// Connects to data-controller="mdb--modal"
export default class extends Controller {
  static memos = ["modalInstance"];
  static values = {
    reference: {type: String, default: "no-reference"}
  };

  connect() {
    useMemo(this);

    this.show();
    document.addEventListener(this.referenceValue + "--" + "close-modal", _ev => {
      this.close();
    });
  }

  show() {
    this.modalInstance.show();
  }

  close() {
    this.modalInstance.hide();
  }

  disconnect() {
    this.modalInstance.dispose();
  }

  get modalInstance() {
    const modal = new Modal(this.element, {keyboard: false, show: false});
    this.element.addEventListener("hidden.mdb.modal", _ev => {
      // Remove the src attribute and innerHTML of the turbo-frame
      const parentTurboFrame = this.element.closest("turbo-frame");
      parentTurboFrame.removeAttribute("src");
      parentTurboFrame.innerHTML = "";
      // Dispatch a custom event to notify the parent controller that the modal has been closed
      const closeEvent = new CustomEvent(this.referenceValue + "--" + "modal-closed");
      document.dispatchEvent(closeEvent);
    });
    return modal;
  }
}
