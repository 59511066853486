import React, { useEffect, useState, useRef } from 'react';
import axios from "axios";
import { useSnackbar } from 'notistack';

import { makeStyles } from "@material-ui/core/styles";
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Button from '@material-ui/core/Button';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import ReloadIcon from '@material-ui/icons/Cached';
import DownloadIcon from '@material-ui/icons/CloudDownload';
import Skeleton from '@material-ui/lab/Skeleton';

import RecordStatus from './RecordStatus';
import DateTimeField from './DateTimeField';

const useStyles = makeStyles(theme => ({
  root: {
    maxWidth: '450px'
  },
  dangerLink: {
    color: theme.palette.error.main,
  }
}))

function DataView(props) {
  const {
    dataUrl,
    queryScope
  } = props;

  const classes = useStyles();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const [loading, setLoading] = useState(false);
  const [record, setRecord] = useState({});

  const handleReload = _ => loadData();

  const handleError = message => showErrorMessage(message)

  const showErrorMessage = message => {
    enqueueSnackbar(message, {
      variant: 'error',
      preventDuplicate: true,
    })
  }

  const Record = ({label, skeletonSize=150, children}) => {
    return (
      <Grid container item justifyContent="space-between" alignItems="center">
        <Typography variant="body2" component="div">{label}</Typography>
        <Typography variant="body1" component="div">
          {
            loading ?
            <Skeleton animation="wave" variant="text" width={skeletonSize} /> :
            children
          }
        </Typography>
      </Grid>
    );
  }

  const Author = ({user}) => {
    if (!user) return null
    return `${user.name} <${user.email}>`
  }

  const loadData = _ => {
    if (!dataUrl) return;

    const params = {
      ...queryScope
    };

    setLoading(true)
    return axios({
        url: dataUrl,
        method: 'get',
        headers: {'accept': 'application/json'},
        params: params
      })
      .then(response => {
        setRecord(response.data.data);
        // console.log(response)
      })
      .catch(error => {
        const response = error.response;
        showErrorMessage(`${response.statusText}: ${response.data.error}`)
      }).then(_ => {
        setLoading(false)
      });
  }

  useEffect(_ => {
    loadData();
  }, []);

  return (
  <div>
    <Card className={classes.root} variant="outlined">
      <CardContent>
        <Grid container spacing={2}>
          <Record label="Protocolo">{record.protocol}</Record>
          <Record label="Planilha">{record.filename}</Record>
          <Record label="Status">
            <RecordStatus
              status={record.status}
              error={record.error}
              time={record.total_time}
            />
          </Record>
          {record.error && <Record label="Erro">{record.error}</Record>}
          <Record label="Author">
            <Author user={record.user} />
          </Record>
          <Record label="Criado em"><DateTimeField value={record.created_at} /></Record>
          <Record label="Iniciado em"><DateTimeField value={record.started_at} /></Record>
          <Record label="Finalizado em"><DateTimeField value={record.finished_at} /></Record>
        </Grid>
        <FooterButtons
          loading={loading}
          status={record.status}
          error={record.error}
          links={record.links}
          onReload={handleReload}
          onError={handleError}
        />
      </CardContent>
    </Card>
  </div>
  )
}

function FooterButtons({loading, status, error, links, onError, onReload}) {
  const [anchorEl, setAnchorEl] = useState();
  const finished = (status == 'finished')
  const [downloading, setDownloading] = useState(false);

  const handleReload = ev => onReload(ev)
  const handleMenu = ev => setAnchorEl(ev.currentTarget)
  const handleClose = _ => setAnchorEl()

  const doDownload = apiUrl => {
    setDownloading(true)
    return axios({
        url: apiUrl,
        method: 'get',
        headers: {'accept': 'application/json'},
      })
      .then(response => {
        const {url, filename} = response.data;
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download',filename);
        document.body.appendChild(link);
        link.click();
        link.parentNode.removeChild(link);
      })
      .catch(error => {
        const response = error.response;
        onError(`${response.statusText}: ${response.data.error}`)
      }).then(_ => {
        setDownloading(false)
        handleClose();
      });
  }

  return (<>
    <hr />
    <ButtonGroup disabled={loading} size="small" fullWidth disableElevation>
      <Button
        disabled={finished}
        startIcon={<ReloadIcon />}
        onClick={handleReload}
      >
        Recarregar
      </Button>
      <Button
        disabled={downloading || !finished}
        startIcon={<DownloadIcon />}
        onClick={handleMenu}
      >
        Resultados
      </Button>
    </ButtonGroup>
    <Menu
      anchorEl={anchorEl}
      keepMounted
      open={Boolean(anchorEl)}
      onClose={handleClose}
    >
      {!error && <MenuItem onClick={_ => doDownload(links.api_download_result_url)}>Planilha de Resultados calculados</MenuItem>}
      <MenuItem disabled={downloading} onClick={_ => doDownload(links.api_download_log_url)}>Log do processo</MenuItem>
    </Menu>
  </>)
}

export default DataView;