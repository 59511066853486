import React from 'react';

import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import Skeleton from '@material-ui/lab/Skeleton';

function TableRowSkeleton({columns, rows}) {
  return (<>
    {Array(rows).fill().map(_ => (
      <TableRow>
        {Array(columns).fill().map(_ => (
          <TableCell><Skeleton variant="text" /></TableCell>
        ))}
        <TableCell />
      </TableRow>
    ))}
  </>)
}

TableRowSkeleton.propTypes = {
  columns: PropTypes.number.isRequired,
  rows: PropTypes.number,
};

TableRowSkeleton.defaultProps = {
  rows: 3
};

export default TableRowSkeleton;