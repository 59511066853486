import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="calc-test-groups--search"
export default class extends Controller {
  static targets = ["form"];

  search(_event) {
    this.formTarget.requestSubmit()
  }
}
