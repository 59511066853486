import React, { useEffect, useState } from 'react';
import PropTypes from "prop-types";

import { makeStyles, withStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';

const AutocompleteStyled = withStyles(theme => ({
  root: {
    borderRadius: 4,
    position: 'relative',
    backgroundColor: theme.palette.background.paper,
    border: '1px solid #ced4da',
    padding: '5px 10px',
    transition: theme.transitions.create(['border-color', 'box-shadow']),
    '&:focus': {
      borderRadius: 4,
      borderColor: '#80bdff',
      boxShadow: '0 0 0 0.2rem rgba(0,123,255,.25)',
    },
  },
  inputRoot: {
    "&&&:before": {
      borderBottom: "none"
    },
    "&&:after": {
      borderBottom: "none"
    }
  },
  input: {
    '&[type="text"]': {
      border: 0
    },
    '&[type="text"]:focus': {
      border: 0
    }
  },
}))(Autocomplete);

function AutoCompleteRemote(props) {
  const {
    options,
    onChange,
    defaultValue,
    loading
  } = props

  const [value, setValue] = useState(defaultValue.toString());

  const handleChange = (ev, newValue) => setValue(newValue)

  useEffect(_ => {
    onChange(value);
  }, [value])

  return (
    <AutocompleteStyled
      autoComplete={true}
      disableClearable={true}
      fullWidth={true}
      noOptionsText="Não há items"
      options={options}
      value={value}
      loading={loading}
      onChange={handleChange}
      renderInput={params => <TextField {...params} fullWidth />}
    />
  )
}

AutoCompleteRemote.propTypes = {
  options: PropTypes.array.isRequired,
  defaultValue: PropTypes.string,
  onChange: PropTypes.func,
};

AutoCompleteRemote.defaultProps = {
  defaultValue: "",
  options: [],
  onChange: () => {}
};

export default AutoCompleteRemote;