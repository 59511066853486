import { useRef, useEffect } from "react";

export function usePrevious(value) {
  const ref = useRef();
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
}

export function clearForm(myFormElement) {
  var elements = myFormElement.elements;
  myFormElement.reset();
  for(var i=0; i < elements.length; i++) {
    var field_type = elements[i].type.toLowerCase();
    switch(field_type) {
      case "text":
      case "password":
      case "textarea":
      case "hidden":
      case "file":
        elements[i].value = "";
        break;
      case "radio":
      case "checkbox":
        if (elements[i].checked) {
          elements[i].checked = false;
        }
        break;
      case "select-one":
      case "select-multi":
        elements[i].selectedIndex = -1;
        break;
      default:
        break;
    }
  }
}