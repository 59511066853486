import { Controller } from "@hotwired/stimulus"
import { toggleVisible } from "./utils/visibility";
import { sliceChunk } from "./utils/array";
import { useMemo, useDebounce } from 'stimulus-use'
import axios from "axios";

// Connects to data-controller="batch-request-with-progress"
export default class extends Controller {
  static debounces = [
    'confirmationCheck'
  ];
  static memos = [
    'csrfToken', 'formEl', 'formMethod',
    'batchGroupSelectedIds', 'progressBar',
    'ids'
  ]
  static targets = [
    "btnSubmit", "confirmation",
    "progressBar", "runningInfo",
    "finishedInfo", "log"
  ];
  static values = {
    confirmation: String,
    formId: String,
    ids: Array
  }
  currentValue = 0

  connect() {
    useMemo(this)
    useDebounce(this, { wait: 300 })
    this.btnSubmitTarget.disabled = true
  }

  confirmationCheck(ev) {
    this.btnSubmitTarget.disabled = this.confirmationValue !== ev.target.value;
  }

  execute(ev) {
    ev.preventDefault();
    this._callEndpoints()
  }

  async _callEndpoints() {
    this.currentValue = 0
    this._progressStarted()
    for(const groupIds of this.batchGroupSelectedIds) {
      const requests = groupIds.map(ids => this._doRequest(ids))
      await Promise.all(requests)
    }
    this._progressFinished()
  }

  _doRequest(ids) {
    try {
      let formData = new FormData(this.formEl);
      formData.append('ids', ids)
      return axios({
        url: this.formEl.action,
        method: this.formMethod,
        data: formData,
        headers: {
          'Accept': 'text/vnd.turbo-stream.html; charset=utf-8',
          'Content-Type': 'text/vnd.turbo-stream.html; charset=utf-8',
          'X-CSRF-TOKEN': this.csrfToken
        }
      }).then(response => {
        Turbo.renderStreamMessage(response.data)
        return response.status
      }).catch(err => {
        this._addLog(`${ids}: ${err.message}`)
        return err.toJSON()
      }).finally(_ => {
        this.currentValue += ids.length
        this._incrementProgressBar()
      })
    } catch(err) {
      this._addLog(`${ids}: ${err.message}`)
      return err.toJSON()
    }
  }

  _processRunning(running) {
    toggleVisible(this.runningInfoTarget, running === true)
    toggleVisible(this.progressBarTarget, running === true)
    toggleVisible(this.btnSubmitTarget, running === false)
  }

  _progressStarted() {
    toggleVisible(this.finishedInfoTarget, false)
    this._processRunning(true)
    if (this.hasLogTarget) this.logTarget.innerHTML = ''
  }


  _progressFinished() {
    this._processRunning(false)
    toggleVisible(this.finishedInfoTarget, true)
    toggleVisible(this.btnSubmitTarget, false)
  }

  _incrementProgressBar() {
    const newValue = this._progressStep(this.currentValue)
    const newValuePerc = `${newValue}%`
    this.progressBar.setAttribute("aria-valuenow", newValue);
    this.progressBar.style.width = newValuePerc
    this.progressBar.innerHTML = newValuePerc
  }

  _progressStep(current) {
    const totalIds = parseFloat(this.ids.length || 0.0)
    if (totalIds === 0) return;

    return Math.ceil((current / totalIds) * 100.0)
  }

  _addLog(message, type="info") {
    const node = document.createElement("li");
    const textNode = document.createTextNode(message);
    node.appendChild(textNode);
    this.logTarget.appendChild(node);
  }

  get batchGroupSelectedIds() {
    return sliceChunk(sliceChunk(this.ids, 20), 10)
  }

  get csrfToken() {
    return document.querySelector("meta[name='csrf-token']").content
  }

  get progressBar() {
    return this.progressBarTarget.querySelector(".progress-bar")
  }

  get formEl() {
    return document.querySelector(`#${this.formIdValue}`)
  }

  get formMethod() {
    const methodEl = this.formEl.querySelector('input[name=_method]')
    if (typeof(methodEl) === "object" && !methodEl) return this.formEl.method

    return methodEl.value
  }

  get ids() {
    if (this.hasIdsValue === false) return []

    return this.idsValue
  }

}
