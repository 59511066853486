import { Controller } from "@hotwired/stimulus"
import { updateUrlParam } from "../utils/url"
import { toggleDisabled } from "../utils/visibility";

// Connects to data-controller="account-tests--table"
export default class extends Controller {
  static targets = [ "btnApprove", "btnReprove", "btnEditValidity" ];
  selectedIds = []

  connect() {}

  onCheckboxChange({ detail: { pageValues, searchAllChecked, searchParamValues }}) {
    this._disableButtons(pageValues.length < 1 && searchAllChecked === false)
    this.selectedIds = pageValues
    this._updateUrlParam('ids', pageValues)
  }

  _updateUrlParam(paramName, values) {
    this.btnApproveTarget.href = updateUrlParam(this.btnApproveTarget.href, paramName, values)
    this.btnReproveTarget.href = updateUrlParam(this.btnReproveTarget.href, paramName, values)
    if (this.hasBtnEditValidityTarget) {
      this.btnEditValidityTarget.href = updateUrlParam(this.btnEditValidityTarget.href, paramName, values)
    }
  }

  _disableButtons(disabled=false) {
    toggleDisabled(this.btnApproveTarget, disabled)
    toggleDisabled(this.btnReproveTarget, disabled)
    if (this.hasBtnEditValidityTarget) {
      toggleDisabled(this.btnEditValidityTarget, disabled)
    }
  }

}
