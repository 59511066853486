import { Controller } from "@hotwired/stimulus";
// import { Tab } from "../../vendor/mosaic_css/js/mdb.pro";

// Connects to data-controller="mdb--tab"
export default class extends Controller {
  static targets = ["tab"];

  connect() {
    this.tabTargets.forEach(item => item.addEventListener("click", (_ev) => {
          this.removeActive();
          this.addActive(_ev.target);
        }
      )
    );
  }

  addActive(element) {
    element.classList.add("active");
  }

  removeActive() {
    const activeElement = this.tabTargets.find(item => item.classList.contains("active"));
    activeElement.classList.remove("active");
  }
}
