import { Controller } from "@hotwired/stimulus";
import { delegate, abnegate } from "jquery-events-to-dom-events";
import axios from "axios";

// Connects to data-controller="check-on-change"
export default class extends Controller {
  static targets = ["input", "response"];
  static values = {url: String};

  connect() {
    if (this.hasInputTarget) {
      this.inputTarget.delegate = delegate("change");
      this.inputTarget.addEventListener("change", ev => this.handlerChange(ev));
      this.inputTarget.addEventListener("$change", ev => this.handlerChange(ev));
    }
  }

  disconnect() {
    if (this.hasInputTarget) {
      abnegate("change", this.inputTarget.delegate);
      this.inputTarget.removeEventListener("change", ev => this.handlerChange(ev));
      this.inputTarget.removeEventListener("$change", ev => this.handlerChange(ev));
    }
  }

  setResponseAlert(type) {
    this.responseTarget.classList.remove("alert-warning");
    this.responseTarget.classList.remove("alert-danger");
    this.responseTarget.classList.remove("alert-info");
    this.responseTarget.classList.add(`alert-${type}`);
  }

  responseContainer(state) {
    if (state === "show") {
      this.responseTarget.classList.add("show");
    } else {
      this.responseTarget.classList.remove("show");
    }
  }

  handlerChange(event) {
    this.responseContainer("hide");
    const elementValue = event.target.value;
    if (elementValue === "") return;

    axios
    .get(this.urlValue, {params: {value: elementValue}})
    .then(response => {
      if (response.status === 200) {
        this.setResponseAlert("success");
      } else {
        this.setResponseAlert("warning");
      }
      this.responseTarget.innerHTML = response.data;
    })
    .catch(error => {
      this.setResponseAlert("danger");
      if (error.response) {
        this.responseTarget.innerHTML = error.response.data;
      } else {
        this.responseTarget.innerHTML = error.message;
      }
    }).then(_ => {
      this.responseContainer("show");
    });
  }
}
