import React from 'react';
import { SnackbarProvider } from 'notistack';
import { ConfirmProvider } from 'material-ui-confirm';
import moment from 'moment'
import 'moment/locale/pt-br';
import { HashRouter as Router } from "react-router-dom";

import DataView from './scenariosCalculations/DataView';

moment.locale('pt-br');

function ScenariosCalculation(props) {
  return (
    <Router>
      <SnackbarProvider maxSnack={3}>
        <ConfirmProvider
          defaultOptions={{
            confirmationText: 'Sim!',
            cancellationText: 'Cancelar',
            title: 'Você tem certeza?'
          }}
        >
          <DataView {...props} />
        </ConfirmProvider>
      </SnackbarProvider>
    </Router>
  );
}

export default ScenariosCalculation;