import { Controller } from "@hotwired/stimulus";
import { updateUrlParam } from "../utils/url";
import { Autocomplete } from "../../vendor/mosaic_css/js/mdb.pro";

// Connects to data-controller="mdb--auto-complete"
export default class extends Controller {

  connect() {
    new Autocomplete(this.element, {
      filter: this._asyncFilter,
      displayValue: value => {
        this.hiddenField.value = value.id;
        return value.text;
      },
      itemContent: (result) => {
        return `<span data-id="${result.id}">${result.text}</span>`;
      }
    });
  }

  disconnect() {
    Autocomplete.getInstance(this.element).dispose();
  }

  _asyncFilter = async (query) => {
    const dataUrl = this.inputField.dataset.url;
    const url = updateUrlParam(dataUrl, "search[keyword]", query);
    this.hiddenField.value = "";
    const response = await fetch(url);
    return await response.json();
  };

  get inputField() {
    return this.element.querySelector("input[type=\"text\"]");
  }

  get hiddenField() {
    return this.element.querySelector("input[type=\"hidden\"]");
  }
}
