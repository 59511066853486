
import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from "prop-types";
import axios from "axios";

import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';

import RuntimeInfoItem from './RuntimeInfoItem'

const RuntimeInfoStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  divider: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2)
  },
}));

function RuntimeInfo(props) {
  const {
    runtimeUrl,
    show,
    name,
    onClose
  } = props;

  const classes = RuntimeInfoStyles();
  const [isWorking, setWorking] = useState(false);
  const [runtime, setRuntime] = useState({
    url: {label: '', value: ''},
    economy_mode: {label: '', value: ''},
    shutdown_business_hours: {label: '', value: ''},
    high_availability: {label: '', value: ''},
    runtime_status: {label: '', value: ''},
    created_at: {label: '', value: ''},
    extras: {
      getrulescount: {content: ''},
      versioncomponent: {content: ''},
      versionlogic: {content: ''},
      versioninterface: {content: ''},
      versiondelta: {content: ''},
    }
  });

  const handleClose = _ => {
    onClose(false)
  }

  function getInfo() {
    setWorking(true);

    return axios({
        url: runtimeUrl,
        method: 'get',
        headers: {'accept': 'application/json'},
        params: {extras: true}
      })
      .then(response => {
        // console.log(response);
        setRuntime(response.data)
      })
      .catch(error => {
        console.log(error)
      }).then(_ => setWorking(false));
  }

  useEffect(() => {
    if (show) {
      getInfo();
    }
  }, [show]);
  
  return (
    <div>
      <Dialog
        open={show}
        onClose={handleClose}
        maxWidth={'lg'}
      >
        <DialogTitle id="form-dialog-title">{name}</DialogTitle>
        <DialogContent>
          <div className={classes.root}>
            <RuntimeInfoItem
              label={runtime.url.label}
              value={runtime.url.value}
              loading={isWorking}
            />
            <RuntimeInfoItem
              label={runtime.economy_mode.label}
              value={runtime.economy_mode.value}
              loading={isWorking}
            />
            <RuntimeInfoItem
              label={runtime.shutdown_business_hours.label}
              value={runtime.shutdown_business_hours.value}
              loading={isWorking}
            />
            <RuntimeInfoItem
              label={runtime.high_availability.label}
              value={runtime.high_availability.value}
              loading={isWorking}
            />
            <RuntimeInfoItem
              label={runtime.runtime_status.label}
              value={runtime.runtime_status.value}
              loading={isWorking}
            />
            <RuntimeInfoItem
              label={runtime.created_at.label}
              value={runtime.created_at.value}
              loading={isWorking}
            />

            <Divider className={classes.divider} />

            <RuntimeInfoItem
              label="getrulescount"
              value={runtime.extras.getrulescount.content}
              loading={isWorking}
            />
            <RuntimeInfoItem
              label="versioncomponent"
              value={runtime.extras.versioncomponent.content}
              loading={isWorking}
            />
            <RuntimeInfoItem
              label="versionlogic"
              value={runtime.extras.versionlogic.content}
              loading={isWorking}
            />
            <RuntimeInfoItem
              label="versioninterface"
              value={runtime.extras.versioninterface.content}
              loading={isWorking}
            />
            <RuntimeInfoItem
              label="versiondelta"
              value={runtime.extras.versiondelta.content}
              loading={isWorking}
            />
          </div>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Fechar
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

RuntimeInfo.propTypes = {
  runtimeUrl: PropTypes.string.isRequired,
  show: PropTypes.bool.isRequired,
  onClose: PropTypes.func
};

RuntimeInfo.defaultProps = {
  show: false,
};

export default RuntimeInfo;