import { Controller } from "@hotwired/stimulus";
import { Chart } from "../../vendor/mosaic_css/js/mdb.pro";
import { dataVisColorVars, dataVisOneColorVar } from "./utils/colors";

// Connects to data-controller="mdb--chart"
export default class extends Controller {
  static values = {
    indexAxis: {type: String, default: "x"},
    type: {type: String, default: "bar"},
    legendDisplay: {type: Boolean, default: true},
    legendPosition: {type: String, default: "bottom"},
    labels: {type: Array, default: []},
    datasets: {type: Array, default: []},
    displayXAxisTicks: {type: Boolean, default: true},
    displayYAxisTicks: {type: Boolean, default: true}
  };

  connect() {
    this._createCanvas();
    const datasets = this.datasetsValue.map((dataset, index) => ({
      label: dataset.label,
      data: dataset.data,
      backgroundColor: (this.typeValue === "bar") ? dataVisOneColorVar() : dataVisColorVars()
    }));

    let options = {};

    if (this.typeValue === "bar") {
      options = this._defaultOptionsBars();
    }

    new Chart(this._canvas, {
        type: this.typeValue,
        data: {
          labels: this.labelsValue,
          datasets: datasets
        }
      },
      {
        options: {
          ...options,
          responsive: true,
          maintainAspectRatio: false,
          indexAxis: this.indexAxisValue,
          onClick: (_e) => {
            // alert("Cliquei!");
          },
          plugins: {
            legend: {
              display: this.legendDisplayValue,
              position: this.legendPositionValue,
              labels: {
                usePointStyle: true
              }
            }
          }
        }
      });
  }

  disconnect() {
    const mdbChart = Chart.getInstance(this._canvas);
    mdbChart.dispose();
  }

  _createCanvas() {
    if (this._canvas) return;
    if (this.element.nodeName === "CANVAS") {
      this._canvas = this.element;
    } else {
      this._canvas = document.createElement("canvas");
      this.element.appendChild(this._canvas);
    }
  }

  _defaultOptionsBars(options) {
    return {
      ...options,
      categoryPercentage: 1,
      barPercentage: 0.9,
      scales: {
        x: {
          ticks: {
            display: this.hideXAxisTicksValue
          }
        },
        y: {
          ticks: {
            display: this.hideYAxisTicksValue
          }
        }
      }
    };
  }
}
