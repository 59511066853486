import { Controller } from "@hotwired/stimulus";
import { SwalConfirm } from "@plugins/sweetalert2";

// Connects to data-controller="mosaic-bs--form-search"
export default class extends Controller {
  static targets = ["form"];
  static values = {
    submitConfirmation: {type: String, default: ""}
  };

  formTargetConnected() {
    this._resetConfirmSubmit();
  }

  search(event) {
    if (event.defaultPrevented === false) event.preventDefault();

    this.formTarget.requestSubmit();
    this._resetConfirmSubmit();
  }

  refresh(event) {
    if (event.defaultPrevented === false) event.preventDefault();

    this.formTarget.dataset.confirmed = "true";
    this.search(event);
  }

  confirmSubmit(event, cb) {
    if (this.submitConfirmationValue === "") return;
    if (this.submitConfirmed) return;
    event.preventDefault();

    SwalConfirm.fire({
      html: this.submitConfirmationValue
    }).then(action => {
      if (action.isConfirmed) {
        this.formTarget.dataset.confirmed = "true";
        this.search(event);
      }
      if (cb !== undefined) cb(action);
    });
  }

  setFieldValueByName(name, value) {
    const input = this.getFieldElementByName(name);
    if (input !== null) input.value = value;
  }

  unsetFieldByName(name) {
    const input = this.getFieldElementByName(name);
    if (input !== null) input.value = "";
  }

  getFieldValueByName(name) {
    const input = this.getFieldElementByName(name);
    if (input !== null) return input.value;
    return undefined;
  }

  getFieldElementByName(name) {
    return this.formTarget.querySelector(`[name="${name.trim()}"]`);
  }

  _resetConfirmSubmit() {
    this.formTarget.dataset.confirmed = "false";
  }

  get submitConfirmed() {
    return this.formTarget.dataset.confirmed.toString() === "true";
  }

  get formDataQueryString() {
    const formData = new FormData(this.formTarget);
    const params = new URLSearchParams(formData);
    return params.toString();
  }
};