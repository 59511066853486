import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="accounts--products--classifica-lote"
export default class extends Controller {

    static targets = [
        'ncmDescInput',
        'ncmDesc',
        'exNcmDescInput',
        'exNcmDesc',
        'exTipiDescInput',
        'exTipiDesc',
        'exTecDescInput',
        'exTecDesc'
    ]
    connect() {
        this.ncmDescInputTarget.addEventListener("change", event => this.carregarDados())
    }

    show_ncm(ncm_val) {
        let ncm_desc = this.ncmDescTarget
        let url = "/ncm_description" + `/${ncm_val}`

        if (ncm_val === "") {
            ncm_desc.innerHTML = ""
        } else {
            Rails.ajax({
                    url:url,
                    type: "GET",
                    cache: false,
                    success: function (result) {
                        if(result === undefined || result === null){
                            ncm_desc.innerHTML = "Não foi encontrada NCM na base de dados."
                        }else{
                            ncm_desc.innerHTML = `<ul>
                            <li>Descrição da NCM => ${result['description']}</li>
                            <li> Tipo da NCM => ${result['ncm_type']}</li>
                            </ul>`

                        }

                    },
                    error: function (result) {
                        ncm_desc.innerHTML = "Ops..."
                    }
                });
        }
    }


    carregarDados() {
        let ncm_code_atual = this.ncmDescInputTarget.value;
        this.show_ncm(ncm_code_atual);
    }

}
