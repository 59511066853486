import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="tax-laws--code"
export default class extends Controller {
    static targets = ["input", "setter"]

    connect(){
        this.setterTargets.forEach(input => {
            if (input.type === "text" || input.type === "number") {
                input.addEventListener("input", _ev => this.build())
            } else {
                input.addEventListener("select2-change", _ev => this.build())
            }
        });
    }
    build(){
        let code = ""
        this.setterTargets.forEach(input => {if(input.value.length !== 0){code+= "/" + input.value;}});
        let codes = code.split("/");
        if (codes[0].length < 1){ code = code.replace("/",""); }
        this.inputTarget.value = code;
    }


}
