export const sliceChunk = (inputArray, perChunk) => inputArray.reduce((resultArray, item, index) => {
  const chunkIndex = Math.floor(index / perChunk);

  if (!resultArray[chunkIndex]) {
    resultArray[chunkIndex] = []; // start a new chunk
  }

  resultArray[chunkIndex].push(item);

  return resultArray;
}, []);

export const addValueToList = (list, value) => {
  if (!list) list = [];
  if (typeof list === "string") list = list.split(",").map(item => item.trim());
  if (!list.includes(value)) list.push(value);

  return list.join(",");
};

export const delValueFromList = (list, value) => {
  if (!list) list = [];
  if (typeof list === "string") list = list.split(",").map(item => item.trim());
  list = list.filter(item => item !== value);

  return list.join(",");
};