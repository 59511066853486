import {Controller} from "@hotwired/stimulus"
import {useDebounce, useMemo} from 'stimulus-use'

// Connects to data-controller="json-editor"
export default class extends Controller {
  static debounces = []
  static memos = ['editor']
  static targets = ["input", "editor"];
  static values = {
    readOnly: {type: Boolean, default: false},
    elementId: {type: String, default: 'json-editor'},
    indent: {type: Number, default: 4},
  }

  connect() {
    useDebounce(this, {wait: 300})
    useMemo(this)
    this.lastStringContent = ''
    this.build()
  }

  build() {
    this.editor.setAttribute('id', this.elementIdValue)
    this.editor.setAttribute('tabindex', 0)
    this.editor.addEventListener('keyup', _ => this.format())
    if (this.readOnlyValue === false) {
      this.editor.setAttribute('contentEditable', true)
    }
    this.value = this.inputTarget.value
    this.validate()
  }

  update() {
    // this.inputTarget.value = this.value //returns the LAST VALID json string
    this.inputTarget.value = this.rawString //returns the current editor content even if it is invalid json
    this.validate()
  }

  validate() {
    if (this.isValid) {
      this.editor.classList.remove("is-invalid");
      this.element.classList.remove("is-invalid");
    } else {
      this.editor.classList.add("is-invalid");
      this.element.classList.add("is-invalid");
    }
  }

  get isValid() {
    try {
      JSON.parse(this.rawString)
      return true
    } catch (e) {
      return false
    }
  }

  get editor() {
    return this.editorTarget
  }

  get selectedText() {
    if (this.element.getSelection)
      return this.element.getSelection()
    return document.getSelection()
  }

  get caretPointer() {
    const selection = this.selectedText
    if (selection.rangeCount > 0) {
      const range = selection.getRangeAt(0)
      const caretRange = range.cloneRange()
      caretRange.selectNodeContents(this.editor)
      caretRange.setEnd(range.endContainer, range.endOffset)
      const section = caretRange.toString()
      const character = section[section.length - 1]
      const occurrence = this.numberOfOccurrences(section, character)
      return {character, occurrence, section}
    }
    return null
  }

  set caretFromPointer(pointer) {
    const selection = window.getSelection()
    const range = document.createRange()
    let nodesToExplore = this.textNodes(this.editor)
    let occurrence = pointer.occurrence
    let fountAt = 0
    let i = 0

    for (i = 0; i < nodesToExplore.length; i++) {
      const node = nodesToExplore[i]
      fountAt = this.positionOfOccurrence(node.textContent, pointer.character, occurrence)
      if (fountAt >= 0)
        break
      occurrence -= this.numberOfOccurrences(node.textContent, pointer.character)
    }

    fountAt++
    range.setStart(nodesToExplore[i], fountAt)
    range.setEnd(nodesToExplore[i], fountAt)
    selection.removeAllRanges()
    selection.addRange(range)
  }

  escapeRegexString(string) {
    return string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&')
  }

  positionOfOccurrence(string, subString, occurrence) {
    const position = string.split(subString, occurrence).join(subString).length
    return position === string.length ? -1 : position
  }

  numberOfOccurrences(string, subString) {
    return subString ? string.replace(new RegExp(`[^${this.escapeRegexString(subString)}]`, 'g'), '').length : 0
  }

  textNodes(element) {
    let node, list = [], walk = document.createTreeWalker(element, NodeFilter.SHOW_TEXT, null, false)
    while (node = walk.nextNode())
      list.push(node)
    return list
  }

  escapeHtml(input) {
    const replace = [['&', '&amp;'], ['<', '&lt;'], ['>', '&gt;'], ['"', '&quot;'], ["'", '&#039;']]
    return replace.reduce((escaped, replacement) => escaped.replaceAll(...replacement), input)
  }

  formatObject(input, offset = 0) {
    // in JS typeof null returns "object" (legacy bug), for null input we just return null
    if (input === null)
      return '<span class="json-editor-null">null</span>'
    let output = ''
    output += `<span class="json-editor-braces">{</span><br>\n`
    output += Object.keys(input).map((key, index, list) => {
      return `${'&nbsp;'.repeat(offset + this.indentValue)}<span class="json-editor-key" class="json-editor-key"><span class="json-editor-key_quotes">\"</span>${this.escapeHtml(key)}<span class="json-editor-key_quotes">\"</span></span><span class="json-editor-colon">:</span><span class="json-editor-value">${this.formatInput(input[key], offset + this.indentValue)}</span>${index < list.length - 1 ? '<span class="json-editor-comma">,</span>' : ''}<br>\n`
    }).join('')
    output += '&nbsp;'.repeat(offset)
    output += `<span class="json-editor-braces">}</span>`
    return output
  }

  formatArray(input, offset = 0) {
    let output = ''
    output += `<span class="json-editor-brackets">[</span><br>\n`
    output += input.map((value, index, list) => {
      return `${'&nbsp;'.repeat(offset + this.indentValue)}<span>${this.formatInput(value, offset + this.indentValue)}</span>${index < list.length - 1 ? '<span class="json-editor-comma">,</span>' : ''}<br>\n`
    }).join('')
    output += '&nbsp;'.repeat(offset)
    output += `<span class="json-editor-brackets">]</span>`
    return output
  }

  formatString(input) {
    return `<span class="json-editor-string"><span class="json-editor-string_quotes">\"</span>${this.escapeHtml(input)}<span class="json-editor-string_quotes">\"</span></span>`;
  }

  formatBoolean(input) {
    return `<span class="json-editor-${input}">${input}</span>`;
  }

  formatNumber(input) {
    return `<span class="json-editor-number">${input}</span>`;
  }

  formatInput(input, offset = 0) {
    const type = Array.isArray(input) ? 'array' : typeof input
    switch (type) {
      case 'object':
        return this.formatObject(input, offset)
      case 'array':
        return this.formatArray(input, offset)
      case 'string':
        return this.formatString(input)
      case 'boolean':
        return this.formatBoolean(input)
      case 'number':
        return this.formatNumber(input)
      default:
        return input
    }
  }

  format() {
    const pointer = this.caretPointer
    let content = ''
    try {
      content = JSON.parse(this.rawString)
    } catch (exception) {
      return
    }

    const currentStringContent = JSON.stringify(content)
    if (!content || currentStringContent === this.lastStringContent)
      return

    this.editor.innerHTML = this.formatInput(content)
    this.lastStringContent = currentStringContent
    if (pointer && focus) this.caretFromPointer = pointer
  }

  get rawString() {
    // remove %A0 (NBSP) characters, which are no valid in JSON
    return this.editor.innerText?.replaceAll('\xa0', '') || ''
  }

  set rawString(input) {
    this.stringValue = input
  }

  get stringValue() {
    return this.lastStringContent
  }

  set stringValue(input) {
    this.editor.innerText = input
    this.format()
  }

  get value() {
    return this.stringValue
  }

  set value(input) {
    return this.stringValue = input
  }

  get jsonValue() {
    return JSON.parse(this.stringValue)
  }

  set jsonValue(input) {
    this.stringValue = JSON.stringify(input)
  }
}

// PORTED FROM
// https://github.com/LaNsHoR/native-json-editor